// import Rule from '../types/Rule';

// ToDo: Colocar o tipo de error e rules
const errorMessage = (error: any, rules: any) => {

  if(!error)
    return ''

    switch (error.type) {
      case 'required':
        return 'Campo obrigatório';
      case 'minLength':
        return `O campo deve ter no mínimo ${rules[error.type]} caracteres`;
      case 'maxLength':
        return `O campo deve ter no máximo ${rules[error.type]} caracteres`;
      case 'min':
        return `O valor mínimo é ${rules[error.type]}`;
      case 'max':
        return `O valor máximo é ${rules[error.type]}`;
      case 'validate':
        return error.message;
      default:
        return error.message;
    }  
};

export default errorMessage;