import removeNonAlphanumeric from "./removeNonAlphanumeric"

export function formatStringToNumber(value: string) {
  if (typeof value !== 'string') return 0

  let result = value.startsWith('R$') ? value.substring(3) : value
  return Number(result.replaceAll('.', '').replace(',', '.'))
}

export function formatNumberToCurrency(value: number) {
  return value.toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
  })
}

export function formatStringToPhoneMask(value: string) {
  if (typeof value !== 'string') return ''
  
  let phone = ''
  value = removeNonAlphanumeric(value)

  for (let index = 0; index < value.length; index++) {
    if (index === 0) {
      phone += `(${value[index]}`
    } else if (index === 1) {
      phone += `${value[index]}) `
    } else if (index === (value.length === 10 ? 6 : 7)) {
      phone += `-${value[index]}`
    } else {
      phone += `${value[index]}`
    }
  }

  return phone
}
