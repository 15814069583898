const menus = [
  {
    key: 'home',
    name: 'Home',
    route: '/',
    icon: 'home',
    types: [1, 2]
  },
  {
    key: 'network_companies',
    name: 'Rede de Empresas',
    route: '/rede-empresas',
    icon: 'network_companies',
    types: [1]
  },
  {
    key: 'sales',
    name: 'Vendas',
    route: '/vendas',
    icon: 'attach_money',
    types: []
  },
  {
    key: 'extracts',
    name: 'Extratos',
    route: '/extratos',
    icon: 'receipt',
    types: [1, 2]
  },
  {
    key: 'notifications',
    name: 'Notificações',
    route: '/notificacoes',
    icon: 'notifications',
    types: []
  }
]

export default menus