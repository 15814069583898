import { createTheme } from '@mui/material/styles'

export default createTheme({
  palette: {
    primary: {
      light: '#fff',
      main: '#009132',
      dark: '#03762b'
    },
    secondary: {
      main: '#003e9e'
    },
    warning: { 
      main: '#ffa726' 
    }
  },
  typography: {
    fontFamily: ['Inter', 'sans-serif'].join(','),
    button: {
      textTransform: 'none',
      fontSize: '1.2em',
      letterSpacing: '-0.05em'
    },
    body1: {
      fontSize: '1.1em',
      letterSpacing: '-0.05em'
    },
    body2: {
      letterSpacing: '-0.05em'
    },
    h1: {
      letterSpacing: '-0.05em'
    },
    h2: {
      letterSpacing: '-0.05em'
    },
    h3: {
      letterSpacing: '-0.05em'
    },
    h4: {
      fontSize: '2em',
      fontWeight: 600
    },
    h5: {
      letterSpacing: '-0.05em'
    },
    h6: {
      letterSpacing: '-0.05em'
    }
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: { 
          padding: 0
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          margin: '.5rem 0'
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          padding: '0px 10px'
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        root: {
          padding: '4px 8px 5px'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          borderRadius: '11px',
          ':hover': {
            boxShadow: 'none'
          }
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: 0
        }
      }
    }
  }
});