import { AxiosResponse, AxiosError } from 'axios'
import { call, put, select } from 'redux-saga/effects'

import { Creators } from '../ducks/auth'
import GlobalState from '../../types/GlobalState'
import httpRequest from '../../services/httpRequest'

function* requestSaga(
  url: string, 
  type: string, 
  dataContent: any, 
  authenticate: boolean = true, 
  formData: boolean) {
  const token: string = yield select((state: GlobalState): string | undefined => state.Y2FzaGNvbg.user?.token)
  
  try {
    //ToDo: Ajustar o tipo da função call
    const response: AxiosResponse = yield call<any>(httpRequest, url, type, dataContent, authenticate, formData, token)

    return response.data
  } catch (error) {
    const errorResponse = error as AxiosError

    if (errorResponse.response?.status === 401) {
      yield put(Creators.resetUser())
    }

    if (errorResponse.message) {
      throw Error(errorResponse.message)
    }
  }
}

export default requestSaga

