import { call, takeEvery, all, put } from 'redux-saga/effects';

import requestSaga from '../requestSaga';
import * as Url from '../../../constants/url';
import { Types } from '../../ducks/globalAction/city';
import SelectOption from '../../../types/SelectOption';

type ResponseCityListOption = {
  error: boolean;
  cities: Array<SelectOption>;
};

function* requestCityListOption() {
  yield takeEvery(Types.REQUEST_CITY_LIST_OPTION, function* (action: any) {

    try {

      const { query, actionCallback } = action

      const response: ResponseCityListOption = yield call<any>(
        requestSaga,
        Url.CITIES_LIST_OPTION_URL,
        'post',
        { query },
        true,
        false
      );

      yield put(actionCallback(response.cities));
    
    } catch (error) {
      console.error(error)
    }

  });
}

export default function* saga() {
  yield all([requestCityListOption()]);
}
