import createSagaMiddleware from 'redux-saga'
import storage from 'redux-persist/lib/storage'
import { persistStore, persistReducer } from 'redux-persist'
import { createStore, compose, applyMiddleware } from 'redux'
import { encryptTransform } from 'redux-persist-transform-encrypt'

import reducers from './ducks'
import rootSaga from './sagas'

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const persistConfig: any = {
  keyPrefix: '_cas',
  key: 'id',
  storage,
  whitelist: ['Y2FzaGNvbg'],
  transforms: [
    encryptTransform({
      secretKey: 'fb_cashcon'
    })
  ]
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const sagaMiddleware = createSagaMiddleware()
const enhancer = process.env.REACT_APP_ENVIRONMENT === 'development' 
  ? composeEnhancers(applyMiddleware(sagaMiddleware)) : applyMiddleware(sagaMiddleware)

const persistedReducer = persistReducer(persistConfig, reducers())

export default function configureStore() {
  const store = createStore(
    persistedReducer,
    enhancer
  )
  const persistor = persistStore(store)

  sagaMiddleware.run(rootSaga)

  return { store, persistor }
}
