
import TextField from '@mui/material/TextField'
import { DateTimePicker, DatePicker } from '@mui/lab'
import { useFormContext, useController, RegisterOptions } from 'react-hook-form'

import errorMessage from '../../utils/errorMessage'

type DateTimePickerProps = {
  id: string
  name: string
  label: string
  type?: 'date' | 'datetime'
  rules?: RegisterOptions
}

const DateTimePickerComponent = (props: DateTimePickerProps): JSX.Element => {

  const { id, name, label, type = 'date', rules } = props

  const { control } = useFormContext()
  const { field, formState } = useController({
    name,
    control
  })

  if (type === 'datetime')
    return (
      <DateTimePicker
        renderInput={(props) => {
          return <TextField 
            id={id} 
            className='MuiDatePicker' 
            {...props}
            helperText={errorMessage(formState.errors[name], rules)}
            sx={{width: '100%'}}
          />
        }}
        label={label}
        {...field}
        minDate={new Date()}
      />
    )

  return (
    <DatePicker
      renderInput={(props) => {
        return <TextField 
          id={id} 
          className='MuiDatePicker' 
          {...props}  
          sx={{width: '100%'}}
          helperText={errorMessage(formState.errors[name], rules)}
        />
      }}
      label={label}
      {...field}
      minDate={new Date()}
    />
  )
}


export default DateTimePickerComponent