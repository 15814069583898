import Loadable from 'react-loadable'

import { Loading } from '../components'

export const Login = Loadable({
  loader: () => import('../pages/Login'),
  loading: Loading
})

export const Register = Loadable({
  loader: () => import('../pages/Register'),
  loading: Loading
})

export const RegisterForm = Loadable({
  loader: () => import('../pages/Register/Form'),
  loading: Loading
})

export const ForgotPassword = Loadable({
  loader: () => import('../pages/ForgotPassword/'),
  loading: Loading
})

export const ResetPassword = Loadable({
  loader: () => import('../pages/ResetPassword/'),
  loading: Loading
})

export const ThemeWrapper = Loadable({
  loader: () => import('../components/ThemeWrapper'),
  loading: Loading
})

export const Dashboard = Loadable({
  loader: () => import('../pages/Auth/Dashboard'),
  loading: Loading
})

export const NetworkCompanies = Loadable({
  loader: () => import('../pages/Auth/NetworkCompanies'),
  loading: Loading
})

export const Sales = Loadable({
  loader: () => import('../pages/Auth/Sales'),
  loading: Loading
})

export const Extracts = Loadable({
  loader: () => import('../pages/Auth/Extracts'),
  loading: Loading
})

export const Notifications = Loadable({
  loader: () => import('../pages/Auth/Notifications'),
  loading: Loading
})

export const Settings = Loadable({
  loader: () => import('../pages/Auth/Settings'),
  loading: Loading
})

export const ValidateAccount = Loadable({
  loader: () => import('../pages/Auth/ValidateAccount'),
  loading: Loading
})

export const AccountNonActive = Loadable({
  loader: () => import('../pages/Auth/AccountNonActive'),
  loading: Loading
})

export const CompanyInitialSetting = Loadable({
  loader: () => import('../pages/Auth/CompanyInitialSetting'),
  loading: Loading
})

export const CondominiumInitialSetting = Loadable({
  loader: () => import('../pages/Auth/CondominiumInitialSetting'),
  loading: Loading
})

export const SettingsCashback = Loadable({
  loader: () => import('../pages/Auth/Settings/Cashback'),
  loading: Loading
})

export const SettingsBankDataCondominium = Loadable({
  loader: () => import('../pages/Auth/Settings/BankDataCondominium'),
  loading: Loading
})

export const ProfileOrganization = Loadable({
  loader: () => import('../pages/Auth/ProfileOrganization'),
  loading: Loading
})

export const ProfileUser = Loadable({
  loader: () => import('../pages/Auth/ProfileUser'),
  loading: Loading
})

export const Condominiums = Loadable({
  loader: () => import('../pages/Auth/Settings/Condominiums'),
  loading: Loading
})

export const TextDoc = Loadable({
  loader: () => import('../pages/Docs/Text'),
  loading: Loading
})

export const NotFound = Loadable({
  loader: () => import('../pages/NotFound'),
  loading: Loading
})
