import { call, takeEvery, all, put } from 'redux-saga/effects'

import toast from '../../utils/toast'
import requestSaga from './requestSaga'
import * as Url from '../../constants/url'
import ToastType from '../../constants/toastType'
import { Types, Creators } from '../ducks/companyInitialSetting'
import OrganizationSelected from '../../types/OrganizationSelected'

type ResponseSettingInitialCompany = {
  error: boolean
  type: string
  message: string
  organizationSelected: OrganizationSelected
}

function* requestSettingCompanyCashbackSubmit() {
  yield takeEvery(Types.REQUEST_SETTING_COMPANY_CASHBACK_SUBMIT, function* (action: any) {

    try {

      yield put(Creators.setIsLoadingStartButton(true))

      const response: ResponseSettingInitialCompany = 
        yield call<any>(
          requestSaga, 
          Url.SETTINGS_CASHBACK_INITIAL_COMPANY_CASHBACK_URL,
          'post',
          action.values, 
          true,
          false
        )

      yield put(Creators.setIsLoadingStartButton(false))

      if (response.error) {
        toast(ToastType.ERROR, 'Falha ao configurar empresa')
        return
      }
      
      yield put(Creators.setShowSuccessInitialSetting(true))

    } catch (error) {
      console.error(error)
    }
  })
}

export default function* extractSaga() {
  yield all([
    requestSettingCompanyCashbackSubmit()
  ])
}
