
import * as React from 'react'
import { ArrowForward } from '@mui/icons-material'
import { Box, Stepper, Step, StepLabel } from '@mui/material'

import Button from '../Button'

type StepperProps = {
  steps: Array<string>
  activeStep: number
  handleNext: () => void
  handleBack: () => void
  handleReset: () => void
  handleFinish: () => void
  loadingFinishButton?: boolean
  children: React.ReactNode
};

const StepperComponent = (props: StepperProps): JSX.Element => {

  const { 
    steps, 
    activeStep, 
    handleNext, 
    handleBack, 
    handleReset, 
    handleFinish,
    loadingFinishButton = false,
    children 
  } = props;

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          )
        })}
      </Stepper>
      <React.Fragment>
        {children}
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', pt: 2 }}>
          {
            activeStep <= steps.length - 1 && <Button 
              label='Voltar' 
              type='button'
              variant='text'
              onClickEvent={handleBack} 
              disabled={activeStep === 0} 
              sx={{ mr: 1 }}
            />
          }
          {
            activeStep > steps.length - 1 && <Button 
              label='Novo Cadastro' 
              type='button'
              variant='text'
              onClickEvent={handleReset} 
              sx={{ mr: 1 }}
            />
          }
          {
            activeStep < steps.length - 1 && (
              <Button 
                label='Continuar'
                type='button'
                onClickEvent={handleNext} 
                endIcon={<ArrowForward />}
              />
            )
          }
          {
            activeStep === steps.length - 1 && (
              <Button 
                label='Finalizar'
                type='button'
                onClickEvent={handleNext} 
                endIcon={<ArrowForward />}
                loading={loadingFinishButton}
              />
            ) 
          }
          {
            activeStep > steps.length - 1 && (
              <Button 
                label='Ir ao Login'
                type='button'
                onClickEvent={handleFinish} 
              />
            ) 
          }
        </Box>
      </React.Fragment>
    
    </Box>
  );
};

export default StepperComponent;