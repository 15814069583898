import { Box } from '@mui/material'
import { Text } from '../../components'

import CashconLogo from '../../assets/images/logo-cashcon.png'

const LoadingComponent = (): JSX.Element => {

  return <Box
    sx={{
      width: '100%',
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute'
    }}
  >
    <Box 
      component='img'
      src={CashconLogo}
      sx={{
        width: 200,
        marginBottom: '1.5em'
      }}
    />
    <Text 
      type='h3'
      align='center'
      color='primary'
      text='Carregando...'
    />
  </Box>
}

export default LoadingComponent