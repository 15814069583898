import axios, { AxiosInstance, AxiosRequestConfig, AxiosRequestHeaders, AxiosResponse } from 'axios'

const axiosRequestHeader: AxiosRequestHeaders = {
  Accept: 'application/json'
}

const axiosConfig: AxiosRequestConfig = {
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: axiosRequestHeader,
  timeout: 180000
}

const axiosInstance: AxiosInstance = axios.create(axiosConfig)

const httpRequest = async (
  url: string, 
  type: 'get' | 'post' | 'put' | 'delete', 
  dataContent: any, 
  authenticate: boolean = true, 
  formData: boolean = false,
  token: string = ''
): Promise<AxiosResponse> => {

  if (authenticate) {
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`
  }

  axiosInstance.defaults.headers.common['Content-Type'] = !formData 
    ? 'application/json' : 'multipart/form-data'

  const response: AxiosResponse = await axiosInstance[type](url, dataContent, axiosConfig)

  return response
}

export default httpRequest
