import Alert, { AlertColor } from '@mui/material/Alert'

type AlertProps = {
  severity: AlertColor
  message: string | React.ReactNode
}

const AlertComponent = (props: AlertProps) => {

  const { severity, message } = props

  return <Alert severity={severity}>{message}</Alert>
}

export default AlertComponent