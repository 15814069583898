import { call, takeEvery, all, put } from 'redux-saga/effects'

import requestSaga from '../requestSaga'
import * as Url from '../../../constants/url'
import { Types } from '../../ducks/globalAction/cashback'
import SelectOption from '../../../types/SelectOption'

type ResponseCashbackOptions = {
  error: boolean
  cashbacks: Array<SelectOption>
};

function* requestCashbackListOption() {
  yield takeEvery(Types.REQUEST_CASHBACK_LIST_OPTION, function* (action: any) {
    
    try { 
    
      const { categoryId, typeResponse, actionCallback } = action

      const response: ResponseCashbackOptions = yield call<any>(
        requestSaga,
        Url.CASHBACK_OPTIONS_COMPANY_URL,
        'post',
        {
          categoryId,
          typeResponse
        },
        true,
        false
      );

      yield put(actionCallback(response.cashbacks))

    } catch (error) {
      console.error(error)
    }

  });
}

export default function* cashbackSaga() {
  yield all([requestCashbackListOption()]);
}
