import * as React from "react";
import { FormProvider } from "react-hook-form";

type FormProps = {
  // ToDo: formMethods deve ser do tipo UseFormReturn
  formMethods: any;
  // ToDo: Fazer o retorno ser do tipo enviado
  submitEvent?: (values: any) => void;
  children: React.ReactNode;
}

const Form = ({ formMethods, submitEvent, children }: FormProps) => {

  return <FormProvider {...formMethods}>
    <form onSubmit={formMethods.handleSubmit(submitEvent)} style={{ width: '100%' }}>
      {children}
    </form>
  </FormProvider>

};

export default Form;