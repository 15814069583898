import { createActions, createReducer } from 'reduxsauce'

import NetworkCompanyState from '../../types/NetworkCompanyState';

const INITIAL_STATE: NetworkCompanyState = {
  companyList: [],
  companyListOption: [],
  categoryListOption: [],
  subcategoryListOption: []
};

export const { Types, Creators } = createActions({
  setCompanyList: ['companyList'],
  resetNetworkCompany: null,
  setCompanyListOption: ['organizationListOption'],
  setNetworkCompanyCategoryListOption: ['categoryListOption'],
  setNetworkCompanySubcategoryListOption: ['subcategoryListOption'],
  resetNetworkCompanySubcategoryListOption: null,

  // Sagas
  requestCompanyList: ['filter'],

  // ToDo: Avaliar colocar isso em um saga global, para ser usado por outros modulos
  requestCompanyCategoryListOption: ['query', 'actionCallback'] 
})

const setCompanyList = (state = INITIAL_STATE, action: any): NetworkCompanyState => 
  ({ ...state, companyList: action.companyList })

const resetNetworkCompany = (): NetworkCompanyState => ({ ...INITIAL_STATE })

const setCompanyListOption = (state = INITIAL_STATE, action: any): NetworkCompanyState => 
  ({ ...state, companyListOption: action.organizationListOption })

const setNetworkCompanyCategoryListOption = (state = INITIAL_STATE, action: any): 
  NetworkCompanyState => ({ ...state, categoryListOption: action.categoryListOption })

const setNetworkCompanySubcategoryListOption = (state = INITIAL_STATE, action: any): 
  NetworkCompanyState => ({ ...state, subcategoryListOption: action.subcategoryListOption })

const resetNetworkCompanySubcategoryListOption = (state = INITIAL_STATE): 
  NetworkCompanyState => ({ ...state, subcategoryListOption: [] })

const HANDLERS = {
  [Types.SET_COMPANY_LIST]: setCompanyList,
  [Types.RESET_NETWORK_COMPANY]: resetNetworkCompany,
  [Types.SET_COMPANY_LIST_OPTION]: setCompanyListOption,
  [Types.SET_NETWORK_COMPANY_CATEGORY_LIST_OPTION]: setNetworkCompanyCategoryListOption,
  [Types.SET_NETWORK_COMPANY_SUBCATEGORY_LIST_OPTION]: setNetworkCompanySubcategoryListOption,
  [Types.RESET_NETWORK_COMPANY_SUBCATEGORY_LIST_OPTION]: resetNetworkCompanySubcategoryListOption
}

export default createReducer(INITIAL_STATE, HANDLERS)