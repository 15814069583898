import { takeEvery, call, put, all } from 'redux-saga/effects';

import toast from '../../utils/toast';
import requestSaga from './requestSaga';
import * as Url from '../../constants/url';
import ToastType from '../../constants/toastType';
import { Types, Creators } from '../ducks/profileOrganization';
import ProfileOrganization from '../../types/ProfileOrganization';
import { formatStringToPhoneMask } from '../../utils/formatString';

type ProfileOrganizationResponse = {
  error: boolean;
  message: string;
  organization: ProfileOrganization;
};

type ProfileOrganizationSubmitResponse = {
  error: boolean;
  type: string;
  message: string;
};

// ToDo: Avaliar unificar esse método com o de baixo
function* requestProfileOrganization() {
  yield takeEvery(Types.REQUEST_PROFILE_ORGANIZATION, function* (action: any) {
    try {
      yield put(Creators.setProfileOrganizationDisabledButton(true));

      const response: ProfileOrganizationResponse = yield call(
        requestSaga,
        Url.PROFILE_ORGANIZATION_URL,
        'get',
        {},
        true,
        false
      );

      yield put(Creators.setProfileOrganizationDisabledButton(false));

      if (response.error) {
        toast(ToastType.ERROR, response.message);
        return;
      }

      toast(ToastType.SUCCESS, response.message);
      
      const profileOrganization = {
        ...response.organization,
        telephone: formatStringToPhoneMask(response.organization.telephone)
      };
      yield put(Creators.setProfileOrganization(profileOrganization));
    } catch (error) {
      console.error(error);
    }
  });
}

function* requestProfileOrganizationSubmit() {
  yield takeEvery(
    Types.REQUEST_PROFILE_ORGANIZATION_SUBMIT,
    function* (action: any) {
      try {
        yield put(Creators.setProfileOrganizationDisabledButton(true));

        const response: ProfileOrganizationSubmitResponse = yield call(
          requestSaga,
          Url.PROFILE_ORGANIZATION_URL,
          'post',
          action.profileOrganization,
          true,
          false
        );

        yield put(Creators.setProfileOrganizationDisabledButton(false));

        if (response.error) {
          toast(ToastType.ERROR, response.message);
          return;
        }

        toast(ToastType.SUCCESS, response.message);
        yield put(Creators.requestProfileOrganization());
      } catch (error) {
        console.error(error);
      }
    }
  );
}

export default function* saga() {
  yield all([requestProfileOrganization(), requestProfileOrganizationSubmit()]);
}
