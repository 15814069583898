import * as React from 'react'
import Button from '@mui/material/Button'
import { Close } from '@mui/icons-material'
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Breakpoint,
  IconButton,
} from '@mui/material'

type DialogProps = {
  openDialog: boolean
  handleClose?: () => void
  handleConfirm?: () => void
  titleText: string
  fullScreen?: boolean
  maxWidth?: Breakpoint
  children: React.ReactNode
  showCancelButton?: boolean
  showConfirmButton?: boolean
  confirmButtonLabel: string
  confirmButtonDisabled?: boolean
  loadingConfirmButton?: boolean
  cancelButtonLabel?: string
}

// ToDo: Renomear este componente
const DialogComponent = (props: DialogProps): JSX.Element => {

  const { 
    openDialog = false, 
    handleClose, 
    handleConfirm,
    titleText, 
    children,
    maxWidth = 'sm',
    fullScreen = false,
    showCancelButton = true,
    showConfirmButton = true,
    confirmButtonLabel,
    confirmButtonDisabled = false,
    loadingConfirmButton = false,
    cancelButtonLabel
  } = props

  return (
    <Dialog 
      open={openDialog} 
      onClose={handleClose}
      fullWidth
      maxWidth={maxWidth}
      fullScreen={fullScreen} 
    >
      {fullScreen ? (
        <DialogActions sx={{ bgcolor: 'primary.main' }}>
          <DialogTitle
            sx={{
              mr: 'auto',
              fontSize: { xs: '1.1rem', sm: '1.3rem' },
              color: 'primary.light',
            }}
          >
            {titleText}
          </DialogTitle>
          <IconButton sx={{ color: 'primary.light' }} onClick={handleClose}>
            <Close />
          </IconButton>
        </DialogActions>
      ) : (
        <DialogTitle
          sx={{
            mr: 'auto',
            fontSize: { xs: '1.1rem', sm: '1.3rem' },
            color: 'primary.main',
          }}
        >
          {titleText}
        </DialogTitle>
      )}
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        {showCancelButton && (
          <Button onClick={handleClose}>
            {cancelButtonLabel || 'Cancelar'}
          </Button>
        )}
        {showConfirmButton && (
          <Button
            onClick={handleConfirm}
            disabled={confirmButtonDisabled || loadingConfirmButton}
            autoFocus
          >
            {confirmButtonLabel || 'Confirmar'}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default DialogComponent
