import { combineReducers } from 'redux'

import authReducer from './auth'
import saleReducer from './sale'
import extractReducer from './extract'
import indicationReducer from './indication'
import settingCondominiumReducer from './settingCondominium'
import profileUserReducer from './profileUser'
import networkCompanyReducer from './networkCompany'
import settingCashbackReducer from './settingCashback'
import settingBankDataReducer from './settingBankData'
import validateAccountReducer from './validateAccount'
import profileOrganizationReducer from './profileOrganization'
import companyInitialSettingReducer from './companyInitialSetting'

export default function createReducer(injectedReducers = {}): any {
  return combineReducers({
    'Y2FzaGNvbg': authReducer,
    sales: saleReducer,
    extracts: extractReducer,
    indication: indicationReducer,
    networkCompany: networkCompanyReducer,
    validateAccount: validateAccountReducer,
    companyInitialSetting: companyInitialSettingReducer,
    settingCashback: settingCashbackReducer,
    settingBankData: settingBankDataReducer,
    settingCondominium: settingCondominiumReducer,
    profileOrganization: profileOrganizationReducer,
    profileUser: profileUserReducer,
    ...injectedReducers
  })
}