import { call, takeEvery, all, put } from 'redux-saga/effects'

import requestSaga from '../requestSaga'
import * as Url from '../../../constants/url'
import Organization from '../../../types/Organization'
import { Types as OrganizationGlobalActionTypes } from '../../ducks/globalAction/organization'

type ResponseOrganizationList = {
  error: boolean
  organizations: Array<Organization>
}

function* requestCondominiumListOption() {
  yield takeEvery(OrganizationGlobalActionTypes.REQUEST_CONDOMINIUM_LIST_OPTION, 
    function* (action: any) {

    try {
      const { filter, actionCallback } = action

      const response: ResponseOrganizationList = 
        yield call<any>(
          requestSaga, 
          Url.CONDOMINIUMS_COMPANY_LIST_OPTION_URL, 
          'post', 
          filter,
          true, 
          false
        )

      yield put(actionCallback(response.organizations))

    } catch (error) {
      console.error(error)
    }
  })
}

function* requestCompanyListOption() {
  yield takeEvery(OrganizationGlobalActionTypes.REQUEST_COMPANY_LIST_OPTION, 
    function* (action: any) {

    try {

      const { filter, actionCallback } = action

      const response: ResponseOrganizationList = 
        yield call<any>(
          requestSaga, 
          Url.COMPANY_CONDOMINIUM_LIST_OPTION_URL, 
          'post', 
          filter,
          true, 
          false
        )

      yield put(actionCallback(response.organizations))

    } catch (error) {
      console.error(error)
    }
  })
}

function* requestAdministratorListOption() {
  yield takeEvery(OrganizationGlobalActionTypes.REQUEST_ADMINISTRATOR_LIST_OPTION,
    function* (action: any) {
      try {
        const { filter, actionCallback } = action

        const response: ResponseOrganizationList = yield call<any>(
          requestSaga,
          Url.ADMINISTRATOR_CONDOMINIUM_LIST_OPTION_URL,
          'post',
          { query: filter },
          true,
          false
        )

        yield put(actionCallback(response.organizations))
      } catch (error) {
        console.error(error)
      }
    })
}

export default function* organizationSaga() {
  yield all([
    requestCompanyListOption(),
    requestCondominiumListOption(),
    requestAdministratorListOption()
  ])
}
