import { Grid } from '@mui/material';
import { useDispatch } from 'react-redux';
import 'react-image-crop/dist/ReactCrop.css';
import React, { useEffect, useRef } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';

import { Creators as FileCreators } from '../../store/ducks/globalAction/file';

export default function CropImage() {
  const { control, getValues, setValue } = useFormContext();

  const dispatch = useDispatch();

  const cropDataWatch = useWatch({
    control,
    name: ['src', 'crop', 'aspect'],
  });

  const imgRef: React.RefObject<HTMLImageElement> = useRef(null);

  const getImagePath = () => getValues('img.preview.url');

  useEffect(() => {
    setValue('crop', undefined);

    const imagePath = getImagePath();

    dispatch(
      FileCreators.requestFile(imagePath, 'get', false, false, (res: any) => {
        const reader: FileReader = new FileReader();

        reader.addEventListener('load', () => {
          if (!reader.result) return;

          setValue('src', reader.result.toString());
        });

        reader.readAsDataURL(res);
      })
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const centerAspectCrop = (
    mediaWidth: number,
    mediaHeight: number,
    aspect: number
  ) => {
    return centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 90,
        },
        aspect,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    );
  };

  const onImageLoad = (e: any) => {
    const aspect = getValues('aspect');

    if (aspect) {
      const { width, height } = e.currentTarget;

      setValue('crop', centerAspectCrop(width, height, aspect));
    }
  };

  const onCropComplete = (cropData: any) => {
    const image: HTMLImageElement = imgRef.current!;

    if (cropData.width && cropData.height && image) {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d')!;

      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;

      const pixelRatio = window.devicePixelRatio;

      canvas.width = Math.floor(cropData.width * scaleX * pixelRatio);
      canvas.height = Math.floor(cropData.height * scaleY * pixelRatio);

      ctx.scale(pixelRatio, pixelRatio);
      ctx.imageSmoothingQuality = 'high';

      const cropX = cropData.x * scaleX;
      const cropY = cropData.y * scaleY;

      const centerX = image.naturalWidth / 2;
      const centerY = image.naturalHeight / 2;

      ctx.save();

      // 5) Move the crop origin to the canvas origin (0,0)
      ctx.translate(-cropX, -cropY);
      // 4) Move the origin to the center of the original position
      ctx.translate(centerX, centerY);
      // 3) Rotate around the origin
      ctx.translate(-centerX, -centerY);
      ctx.drawImage(
        image,
        0,
        0,
        image.naturalWidth,
        image.naturalHeight,
        0,
        0,
        image.naturalWidth,
        image.naturalHeight
      );

      canvas.toBlob((blob: any) => {
        const { file, preview } = getValues('img');
        let imageName;

        if (preview.secureId) {
          imageName = getImagePath().split('/').pop();
        } else {
          imageName = file.name;
        }

        setValue('imgCropped', new File([blob], imageName));
      }, 'image/jpeg');
    }
  };

  return (
    <>
      {cropDataWatch[0] && (
        <Grid
          container
          justifyContent="center"
          style={{ border: '1px solid rgba(0,0,0,0.32)' }}
        >
          <ReactCrop
            crop={cropDataWatch[1]}
            aspect={cropDataWatch[2]}
            onComplete={onCropComplete}
            onChange={(_, percent) => setValue('crop', percent)}
          >
            <img
              src={cropDataWatch[0]}
              ref={imgRef}
              onLoad={onImageLoad}
              alt="Corte da imagem"
            />
          </ReactCrop>
        </Grid>
      )}
    </>
  );
}
