import { createActions, createReducer } from 'reduxsauce'

import AuthState from '../../types/AuthState'

const INITIAL_STATE: AuthState = {
  user: null,
  isLoginLoading: false,
  showUserBalance: false,
  organizationRelatedList: [],
  showOrganizationSelectDialog: false,
  organizationSelected: null,
  canChangeOrganization: false,
  organizationListOption: [],
  mandateExpired: null,
  active: null
}

export const { Types, Creators } = createActions({
  setIsLoginLoading: ['isLoginLoading'],
  setUser: ['userData'],
  setShowUserBalance: ['showUserBalance'],
  setOrganizationStatus: ['status'],
  setOrganizationSelected: ['organizationSelected'],
  setCanChangeOrganization: ['canChangeOrganization'],
  setUserValidatedAccount: ['validatedAccount'],
  setOrganizationRelatedList: ['organizationRelatedList'],
  setOrganizationListOption: ['organizationListOption'],
  setShowOrganizationSelectDialog: ['showOrganizationSelectDialog'],
  setMandateExpired: ['mandateExpired'],
  setActive: ['active'],
  resetOrganizations: null,
  resetUser: null,
  resetAuth: null,

  // Sagas
  requestUserMandate: null,
  requestActive: null,
  requestUserLogin: ['userCredentials'],
  requestSetOrganizationSelected: ['organizationId'],
  requestOrganizationStatus: null,
  requestOrganizationRelatedList: ['showOrganizationSelectDialog', 'callbackAction'],
  logout: null
})

const setUser = (state = INITIAL_STATE, action: any): AuthState => ({ ...state, user: action.userData })

const resetUser = (state = INITIAL_STATE): AuthState => ({ ...state, user: null })

const resetAuth = ({ showUserBalance }: AuthState): AuthState => ({ ...INITIAL_STATE, showUserBalance })

const setIsLoginLoading = (state = INITIAL_STATE, action: any): AuthState => 
  ({ ...state, isLoginLoading: action.isLoginLoading })

const setShowUserBalance = (state: AuthState = INITIAL_STATE, action: any): AuthState => 
  ({ ...state, showUserBalance: action.showUserBalance })

const setOrganizationStatus = (state: AuthState = INITIAL_STATE, action: any): AuthState =>
  ({ ...state, organizationSelected: { ...state.organizationSelected!, status: action.status }})

const setOrganizationSelected = (state: AuthState = INITIAL_STATE, action: any): AuthState => 
  ({ ...state, organizationSelected: action.organizationSelected })

const setCanChangeOrganization = (state: AuthState = INITIAL_STATE, action: any): AuthState => 
  ({ ...state, canChangeOrganization: action.canChangeOrganization })

const setOrganizationListOption = (
  state: AuthState = INITIAL_STATE, 
  action: any): AuthState => 
    ({ ...state, organizationListOption: action.organizationListOption })

const setMandateExpired = (state: AuthState = INITIAL_STATE, action: any): AuthState =>
  ({ ...state, mandateExpired: action.mandateExpired })

const setActive = (state: AuthState = INITIAL_STATE, action: any): AuthState =>
  ({ ...state, active: action.active })

const setOrganizationRelatedList = (
    state: AuthState = INITIAL_STATE, 
    action: any): AuthState => 
      ({ ...state, organizationRelatedList: action.organizationRelatedList })

const setShowOrganizationSelectDialog = (
  state: AuthState = INITIAL_STATE, 
  action: any): AuthState => 
    ({ ...state, showOrganizationSelectDialog: action.showOrganizationSelectDialog })

const setUserValidatedAccount = (
  state = INITIAL_STATE,
  action: any
): AuthState => ({
  ...state,
  user: {
    ...state.user!,
    validatedAccount:  action.validatedAccount
  }
});

const resetOrganizations = (state: AuthState = INITIAL_STATE): AuthState => 
  ({ ...state, organizationSelected: null, organizationRelatedList: [] })

const HANDLERS = {
  [Types.SET_USER]: setUser,
  [Types.RESET_USER]: resetUser,
  [Types.SET_ACTIVE]: setActive,
  [Types.SET_MANDATE_EXPIRED]: setMandateExpired,
  [Types.SET_IS_LOGIN_LOADING]: setIsLoginLoading,
  [Types.SET_SHOW_USER_BALANCE]: setShowUserBalance,
  [Types.SET_ORGANIZATION_STATUS]: setOrganizationStatus,
  [Types.SET_USER_VALIDATED_ACCOUNT]: setUserValidatedAccount,
  [Types.SET_ORGANIZATION_SELECTED]: setOrganizationSelected,
  [Types.SET_CAN_CHANGE_ORGANIZATION]: setCanChangeOrganization,
  [Types.SET_ORGANIZATION_LIST_OPTION]: setOrganizationListOption,
  [Types.SET_ORGANIZATION_RELATED_LIST]: setOrganizationRelatedList,
  [Types.SET_SHOW_ORGANIZATION_SELECT_DIALOG]: setShowOrganizationSelectDialog,
  [Types.RESET_ORGANIZATIONS]: resetOrganizations,
  [Types.RESET_AUTH]: resetAuth
}

export default createReducer(INITIAL_STATE, HANDLERS)