import { createActions, createReducer } from 'reduxsauce';
import ProfileUserState from '../../types/ProfileUserState';

const INITIAL_STATE: ProfileUserState = {
  profileUser: null,
  profileUserDisabledButton: false,
};

export const { Types, Creators } = createActions({
  setProfileUser: ['profileUser'],
  resetProfileUser: null,
  setProfileUserDisabledButton: ['disabled'],

  // Sagas
  requestProfileUserSubmit: ['profileUser'],
  requestProfileUser: null,
});

const resetProfileUser = (): ProfileUserState => ({ ...INITIAL_STATE });

const setProfileUser = (
  state = INITIAL_STATE,
  action: any
): ProfileUserState => ({
  ...state,
  profileUser: action.profileUser,
});

const setProfileUserDisabledButton = (
  state = INITIAL_STATE,
  action: any
): ProfileUserState => ({
  ...state,
  profileUserDisabledButton: action.disabled,
});

const HANDLERS = {
  [Types.RESET_PROFILE_USER]: resetProfileUser,
  [Types.SET_PROFILE_USER]: setProfileUser,
  [Types.SET_PROFILE_USER_DISABLED_BUTTON]:
    setProfileUserDisabledButton,
};

export default createReducer(INITIAL_STATE, HANDLERS);
