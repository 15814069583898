import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import ptBrLocale from 'date-fns/locale/pt-BR'
import { ToastContainer } from 'react-toastify'
import { LocalizationProvider } from '@mui/lab'
import DateAdapter from '@mui/lab/AdapterDateFns'
import CssBaseline from '@mui/material/CssBaseline'
import { PersistGate } from 'redux-persist/integration/react'

import Routes from './routes'
import { MessageDevelopment } from './components'
import configureStore from '../src/store/configureStore'

import './styles/style.css'
import 'react-toastify/dist/ReactToastify.css';

const { store, persistor } = configureStore()

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <LocalizationProvider dateAdapter={DateAdapter} locale={ptBrLocale}>
        <CssBaseline />
        {
          process.env.REACT_APP_ENVIRONMENT === 'development' && <MessageDevelopment />
        }
        <ToastContainer />
        <Routes />
      </LocalizationProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
)