import { all, call, put, takeEvery } from 'redux-saga/effects';

import toast from '../../utils/toast';
import requestSaga from './requestSaga';
import * as Url from '../../constants/url';
import ToastType from '../../constants/toastType';
import { Creators as AuthCreators } from '../ducks/auth';
import { Creators, Types } from '../ducks/validateAccount';

type ResponseValidateAccount = {
  error: boolean;
  message: string;
  data: any;
};

function* requestValidateAccountSubmit() {
  yield takeEvery(
    Types.REQUEST_VALIDATE_ACCOUNT_SUBMIT,
    function* (action: any) {

      try {

        const { code } = action;

        const response: ResponseValidateAccount = yield call<any>(
          requestSaga,
          Url.VALIDATE_ACCOUNT_URL,
          'post',
          { code },
          true,
          false
        );

        if (response.error) {
          toast(ToastType.ERROR, response.message);
          return;
        }

        yield put(
          AuthCreators.setUserValidatedAccount(response.data.validatedAccount)
        );

      } catch (error) {
        console.error(error)
      }
    }
  );
}

function* requestSendCodeSubmit() {
  yield takeEvery(Types.REQUEST_SEND_CODE_SUBMIT, function* () {

    try {

      yield put(Creators.setLoadingResendCode(true));

      const response: ResponseValidateAccount = yield call<any>(
        requestSaga,
        Url.VALIDATE_ACCOUNT_SEND_CODE_URL,
        'get',
        true,
        false
      );

      yield put(Creators.setLoadingResendCode(false));

      if (response.error) {
        toast(ToastType.ERROR, response.message);
        return;
      }

      if (response.data.lastSentCode)
        yield put(Creators.setLastCodeSentAt(response.data.lastSentCode));

    } catch (error) {
      console.error(error)
    }
  });
}

function* requestLastSentCode() {
  yield takeEvery(Types.REQUEST_LAST_SENT_CODE, function* () {

    try {

      const response: ResponseValidateAccount = yield call<any>(
        requestSaga,
        Url.VALIDATE_ACCOUNT_GET_LAST_SENT_CODE,
        'get',
        true,
        false
      );

      if (!response.error && response.data) {
        yield put(Creators.setUserHasCode(response.data.userHasCode));
        
        if (response.data.userHasCode)
          yield put(Creators.setLastCodeSentAt(response.data.lastSentCode));
      }
    
    } catch (error) {
      console.error(error)
    }
  });
}

export default function* saga() {
  yield all([
    requestValidateAccountSubmit(),
    requestSendCodeSubmit(),
    requestLastSentCode(),
  ]);
}
