import { takeEvery, call, put, all, select } from 'redux-saga/effects'

import Sale from '../../types/Sale'
import toast from '../../utils/toast'
import requestSaga from './requestSaga'
import * as Url from '../../constants/url'
import { Types, Creators } from '../ducks/sale'
import ToastType from '../../constants/toastType'
import ExtractList from '../../types/ExtractList'
import GlobalState from '../../types/GlobalState'
import Organization from '../../types/Organization'
import * as OrganizationType from '../../constants/organizationType'

// ToDo: Ajustar esse response
type BalanceResponse = {
  error: boolean
  balance: number
}

type SaleResponse = {
  error: boolean
  message: string
}

type SaleFilterResponse = {
  error: boolean
  sales: Array<Sale>
  extract: Array<any>
  extractSummary: any
}

function* requestLastSales() {
  yield takeEvery(Types.REQUEST_LAST_SALES, function* () {
    try {
      const { 
        organizationType, 
        lastSalesFilter 
      } = yield select((state: GlobalState) => ({
        organizationType: state.Y2FzaGNvbg.organizationSelected?.type,
        lastSalesFilter: state.sales.lastSalesFilter
      }))
      
      const organizationIsCondominium: boolean = organizationType === OrganizationType.CONDOMINIUM;
  
      const { url, method, payload } = { 
        url: organizationIsCondominium ? `${Url.DASHBOARD_URL}` : Url.SALES_LAST_RELEASES_COMPANY,
        method: organizationIsCondominium ? 'get' : 'post', 
        payload: organizationIsCondominium 
          ? {} : lastSalesFilter 
      }
  
      const response: SaleFilterResponse = yield call(
        requestSaga,
        url,
        method,   
        payload,
        true, 
        false
      )
  
      const responseData = { list: organizationIsCondominium ? response.extract : response.sales }
  
      if (response.error) {
        toast(ToastType.ERROR, 'Falha ao obter lista de vendas')
      } else {
  
        // ToDo: refatorar para que essa propriedade textFinalValueLabel não seja passada
        let lastSales: ExtractList = {
          textFinalValueLabel: organizationIsCondominium ? 'Cashback' : 'Cashback a pagar', 
          data: [],
        }
  
        lastSales.data = responseData.list.map((item: any) => ({
          secureId: item.secureId,
          organizationName: organizationIsCondominium ? item.organization.name : item.organizationClient.name,
          saleDate: item.saleDate,
          finalValue: organizationIsCondominium ? item.cashbackValue : item.value,
          status: item.status,
          description: item.description,
          receivingDate: item.receivingDate,
          category: item.category
        }))
  
        yield put(Creators.setLastSales(lastSales))
        if (organizationIsCondominium) yield put(Creators.setSaleSummary(response.extractSummary))
      }
    } catch (error: any) {
      console.error(`Error to get last sales ${error.message}`)
    }
  })
}

function* requestBalance() {
  yield takeEvery(Types.REQUEST_BALANCE, function* () {
    
    try {
      const organizationSelected: Organization = yield select((state: GlobalState): unknown => {
        return state.Y2FzaGNvbg.organizationSelected
      })

      if (!organizationSelected) {
        yield put(Creators.setBalance(0))
        return; 
      }

      const response: BalanceResponse = yield call(
        requestSaga, 
        `${organizationSelected.type === OrganizationType.CONDOMINIUM 
            ? Url.SALES_TOTAL_CONDOMINIUM_BALANCE_MONTH 
              : Url.SALES_TOTAL_COMPANY_BALANCE_MONTH}`, 
        'get', 
        null,
        true,
        false
      )

      if(response && !response.error)
        yield put(Creators.setBalance(response.balance))
    
    } catch (error) {
      console.error(error)
    }
  })
}

function* requestSaleSubmit() {
  yield takeEvery(Types.REQUEST_SALE_SUBMIT, function* (action: any) {
    
    try {

      yield put(Creators.setSaleDisabledButton(true))
      yield put(Creators.resetSaleForm(false))

      const response: SaleResponse = yield call(
        requestSaga, 
        Url.SALES_COMPANY_URL, 
        'post', 
        action.sale, 
        true, 
        false
      )

      yield put(Creators.setSaleDisabledButton(false))
      
      if (response.error) {
        toast(ToastType.ERROR, response.message)
        return
      }
      
      if (!response.error) {
        toast(ToastType.SUCCESS, response.message)
        yield put(Creators.requestLastSales())
        yield put(Creators.requestBalance())
        yield put(Creators.resetSaleForm(true))
      }  

    } catch (error) {
      console.error(error)
    }
  })
}

export default function* sale() {
  yield all([
    requestBalance(),
    requestLastSales(),
    requestSaleSubmit()
  ])
}