import * as React from 'react'
import { useSelector } from 'react-redux'
import { Help } from '@mui/icons-material'
import { Grid, ListItem, Divider, List, Tooltip } from '@mui/material'

import Text from '../Text'
import ExtractList from '../../types/ExtractList'
import GlobalState from '../../types/GlobalState'
import SaleStatusType from '../../enum/saleStatus'
import { COMPANY } from '../../constants/organizationType'
import { formatNumberToCurrency } from '../../utils/formatString'
import { convertDateWithFns, getDateTimezone } from '../../utils/date'
import { toast } from 'react-toastify'

interface ResponseType {
    type: string
}

const ExtractListComponent = (props: ExtractList): JSX.Element => {
  const { data, showFinalValueStatus = false, textFinalValueLabel } = props
  const { showUserBalance, organizationType, token } = useSelector((state: GlobalState) => ({
    showUserBalance: state.Y2FzaGNvbg.showUserBalance,
    organizationType: state.Y2FzaGNvbg.organizationSelected?.type,
    token: state.Y2FzaGNvbg.user?.token
  }))

  const handleApproved = async (e: any, secureId: string) => {   
    if (e.target.checked) {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/condominium/sales/approve`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({
          secureId,
          status: SaleStatusType.PENDING
        }),
      })
      
      const data: ResponseType = await response.json()
      if (data.type === 'success') {
        toast.success('Venda aprovada com sucesso!')
       
        if (props.onHandleApproved)
          props.onHandleApproved(secureId)

      } else if (data.type === 'salesType') {
        toast.error('Venda não está mais em análise, recarregue a página para atualizar a lista de vendas')
      } else {
        toast.error('Erro ao aprovar venda')
      }
    }
  }


    
  return (
    <List>
      {data ? data.map((item) => (
        <React.Fragment key={item.secureId}>
          
          <ListItem disablePadding sx={{ padding: { xs: '.5rem 0', sm: '.4rem 0', md: '.2rem 0' } }}>
            <Grid container item sm={12} xs={2} spacing={2} sx={{
              flexWrap: 'nowrap',
            }}>
              <Grid item xs={9} sm={organizationType === COMPANY ? 3 : 4}>
                <Text
                  text={getDateTimezone(item.saleDate).replace(' ', ' às ')}
                  sx={{
                    fontSize: '.7rem'
                  }}
                />
                <Text
                  text={item.organizationName}
                  color='secondary'
                  sx={{
                    fontWeight: 600,
                    marginBottom: { xs: '.4rem', sm: 0 }
                  }}
                />
              </Grid>
              {
                item.seller && (
                  <Grid item xs={12} sm={organizationType === COMPANY ? 2 : 3}>
                    <Text
                      text='Vendido por'
                      sx={{
                        fontSize: '.7rem'
                      }}
                    />
                    <Text
                      text={item.seller}
                      type='subtitle2'
                      sx={{
                        marginBottom: { xs: '.4rem', sm: 0 }
                      }}
                    />
                  </Grid>
                )
              }
              {
                item.category && (
                  <Grid item xs={12} sm={3}>
                    <Text
                      text='Categoria'
                      sx={{
                        fontSize: '.7rem'
                      }}
                    />
                    <Text
                      text={item.category}
                      type='subtitle2'
                      sx={{
                        marginBottom: { xs: '.4rem', sm: 0 }
                      }}
                    />
                  </Grid>
                )
              }
              {
                item.receivingDate && organizationType === COMPANY && (
                  <Grid item xs={12} sm={2}>
                    <Text
                      text='Recebimento'
                      sx={{
                        fontSize: '.7rem'
                      }}
                    />
                    <Text
                      text={convertDateWithFns(item.receivingDate)}
                      type='subtitle2'
                      sx={{
                        marginBottom: { xs: '.4rem', sm: 0 }
                      }}
                    />
                  </Grid>
                )
              }
              {
                !!item.saleValue && (
                  <Grid item xs={12} sm={2}>
                    <Text
                      text='Valor da venda'
                      sx={{
                        fontSize: '.7rem'
                      }}
                    />
                    <Text
                      text={
                        showUserBalance
                          ? formatNumberToCurrency(item.saleValue)
                          : 'R$ **'
                      }
                      type='subtitle2'
                    />
                  </Grid>
                )
              }
            </Grid>
            <Grid spacing={2} xs={1} sm={1}>
            {
                item.status === SaleStatusType.IN_ANALYSIS && (
                  <Grid item xs={12} sm={2} sx={{
                    marginBottom: { xs: '1rem', sm: '1rem' }
                  }}>
                    <Text
                      text='Aprovar'
                      sx={{
                        fontSize: '.7rem'
                      }}
                    />
                    <input
                      type="checkbox"
                      name="approved"
                      onClick={(e) => handleApproved(e, item.secureId)}
                      style={{
                        marginBottom: '0.4rem'
                      }}
                      disabled={item.status !== SaleStatusType.IN_ANALYSIS}
                    />
                  </Grid>
                )
              }
            </Grid>
            <Grid 
              container 
              item
              spacing={2}
              xs={3} 
              sm={2}
            >
              {
                !!item.finalValue && (
                  <Grid item xs={12} sm={12}>
                    <Text
                      text={textFinalValueLabel}
                      align='right'
                      sx={{
                        fontSize: '.7rem'
                      }}
                    />
                    <Text
                      text={
                        showUserBalance
                          ? formatNumberToCurrency(item.finalValue)
                          : 'R$ **'
                      }
                      icon={
                        showFinalValueStatus ? (
                          <Tooltip
                              title={
                                item.description ||
                                (item.status === SaleStatusType.APPROVED
                                  ? 'Cashback Pago'
                                  : 'Aguardando Liberação de Cashback')
                              }placement="top"
                              enterTouchDelay={0}

                            >
                            <Help 
                              sx={{ 
                                fontSize: '18px', 
                                color: 'rgba(0,0,0,0.54)', 
                                ml: 0.5
                              }}
                            />
                          </Tooltip>
                        ) : <></>
                      }
                      align='right'
                      type='subtitle2'
                      sx={{
                        fontWeight: 600,
                        color: showFinalValueStatus ? item.status === SaleStatusType.APPROVED
                            ? '#009132' 
                            : item.status === SaleStatusType.PENDING ? '#ffa726' 
                            : "red"
                          : '#000'
                      }}
                    />
                  </Grid>
                )
              }
            </Grid>
          </ListItem>
          <Divider />
        </React.Fragment>
      )) : <Text text='Não há lançamentos' />}
    </List>
  )
}

export default ExtractListComponent
