import { createActions, createReducer } from 'reduxsauce'

import ProfileOrganizationState from '../../types/ProfileOrganizationState';

const INITIAL_STATE: ProfileOrganizationState = {
  profileOrganization: null,
  cityListOption: [],
  profileOrganizationDisabledButton: false,
  administratorListOption: []
}

export const { Types, Creators } = createActions({
  setCityListOption: ['cityListOption'],
  setProfileOrganization: ['profileOrganization'],
  resetProfileOrganization: null,
  setProfileOrganizationDisabledButton: ['disabled'],
  setAdministratorListOption:['administratorListOption'],

  // Sagas
  requestProfileOrganizationSubmit: ['profileOrganization'],
  requestProfileOrganization: null,
  requestCityListOption: ['query']
})

const setAdministratorListOption = (state = INITIAL_STATE, action: any): ProfileOrganizationState => 
  ({ ...state, administratorListOption: action.administratorListOption || []}) 

const setCityListOption = (state = INITIAL_STATE, action: any): ProfileOrganizationState => 
  ({ ...state, cityListOption: action.cityListOption })

const resetProfileOrganization = (): ProfileOrganizationState => ({ ...INITIAL_STATE })

const setProfileOrganization = (state = INITIAL_STATE, action: any): ProfileOrganizationState => 
  ({ ...state, profileOrganization: action.profileOrganization })

const setProfileOrganizationDisabledButton = (state = INITIAL_STATE, action: any): ProfileOrganizationState => 
  ({ ...state, profileOrganizationDisabledButton: action.disabled })

const HANDLERS = {
  [Types.SET_CITY_LIST_OPTION]: setCityListOption,
  [Types.RESET_PROFILE_ORGANIZATION]: resetProfileOrganization,
  [Types.SET_PROFILE_ORGANIZATION]: setProfileOrganization,
  [Types.SET_PROFILE_ORGANIZATION_DISABLED_BUTTON]: setProfileOrganizationDisabledButton,
  [Types.SET_ADMINISTRATOR_LIST_OPTION]: setAdministratorListOption

}

export default createReducer(INITIAL_STATE, HANDLERS)