import { Grid } from '@mui/material'
import { useSelector } from 'react-redux'
import {
  Timeline,
  TimelineDot,
  TimelineItem,
  TimelineContent,
  TimelineSeparator,
  TimelineConnector,
  TimelineOppositeContent
} from '@mui/lab'

import Text from '../Text'
import ExtractList from '../ExtractList'
import { getMonthName } from '../../utils/date'
import GlobalState from '../../types/GlobalState'
import ExtractTimeline from '../../types/ExtractTimeline'
import { formatNumberToCurrency } from '../../utils/formatString'
import { COMPANY } from '../../constants/organizationType'

export default function ExtractTimelineComponent(props: ExtractTimeline) {

  const { data, textFinalValueLabel, showFinalValueStatus = false } = props

  const {showUserBalance, organizationType} = useSelector((state: GlobalState) => ({showUserBalance: state.Y2FzaGNvbg.showUserBalance, organizationType: state.Y2FzaGNvbg.organizationSelected?.type}))

  return (
    <Timeline sx={{ padding: 0 }}>
      {
        data?.map((item, index) => {
          return (
            <TimelineItem key={`${item.data}-${index}`}>
              <TimelineOppositeContent
                sx={{
                  flex: 0,
                  paddingLeft: 0,
                  paddingTop: 1,
                  fontWeight: 600,
                  display: { xs: 'none', sm: 'flex' }
                }}
                align='right'
                variant='body2'
                color='secondary'
              >
                10/{item.date}
              </TimelineOppositeContent>
              <TimelineSeparator sx={{ color: 'red' }}>
                <TimelineDot color='primary' />
                <TimelineConnector sx={{ backgroundColor: 'green' }} />
              </TimelineSeparator>
              <TimelineContent sx={{ paddingRight: 0 }}>
                <Grid container>
                  <Grid item container xs={12}>
                    <Grid item xs>
                      <Text
                        text={`${getMonthName(item.date?.split('/')[0], 'long')}`}
                        type='body1'
                        color='text.secondary'
                      />
                    </Grid>
                    <Grid item xs>
                      <Text
                        text={
                          organizationType === COMPANY
                            ? 'Repasse do mês'
                            : 'Cashback do mês'
                        }
                        align='right'
                        sx={{
                          fontSize: '.7rem'
                        }}
                      />
                      <Text
                        text={
                            showUserBalance
                            ? formatNumberToCurrency(item.balance ? item.balance : 0)
                            : 'R$ **'
                        }
                        type='h6'
                        align='right'
                        color='secondary'
                        sx={{
                          fontWeight: 600
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <ExtractList 
                      textFinalValueLabel={textFinalValueLabel} 
                      showFinalValueStatus={showFinalValueStatus}
                      data={item.data} 
                      onHandleApproved={props.onHandleApproved}
                    />
                  </Grid>
                </Grid>
              </TimelineContent>
            </TimelineItem>
          )
        })
      }
    </Timeline>
  )
}
