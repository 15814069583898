import * as React from 'react'
import { SxProps, Theme } from '@mui/material/styles'
import { Box, Card, CardContent, CardHeader } from '@mui/material'

import Text from '../Text'

type CardProps = {
  variant?: 'elevation' | 'outlined' | undefined
  elevation?: number
  leftTitle?: string
  rightTitle?: React.ReactNode
  children?: React.ReactNode
  sx?: SxProps<Theme>
};

const CardComponent = (props: CardProps): JSX.Element => {

  const { 
    variant = 'elevation', 
    elevation = 2, 
    leftTitle, 
    rightTitle, 
    children, 
    sx 
  } = props;

  return <Card
    variant={variant}
    elevation={elevation}
    sx={{
      ...sx,
      width: '100%'
    }}
  >
    {
      (leftTitle || rightTitle) && (
        <CardHeader 
          title={
            <Box 
              display='flex'
              justifyContent={(leftTitle && rightTitle) ? 'space-between' : leftTitle ? 'flex-start' : 'flex-end'}
              alignItems='center'
            >
              {
                leftTitle && (
                  <Text 
                    text={leftTitle}
                    color='primary'
                    sx={{
                      fontSize: '1.1rem',
                      fontWeight: 500
                    }}
                  />
                )
              }
              { rightTitle }
            </Box>
          }
        />
      )
    }
    <CardContent>
      {children}
    </CardContent>
  </Card>
};

export default CardComponent;