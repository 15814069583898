import { useDispatch, useSelector } from 'react-redux'
import { Grid, Box, MenuList, MenuItem } from '@mui/material'
import { Link as LinkRouter, useNavigate } from 'react-router-dom'
import {
  Settings,
  HeadsetMic,
  ChangeCircle,
  AccountCircle
} from '@mui/icons-material'

import Text from '../Text'
import Link from '../Link'
import Button from '../Button'
import ValueHeader from './ValueHeader'
import ButtonDropdown from '../ButtonDropdown'
import GlobalState from '../../types/GlobalState'
import { Creators } from '../../store/ducks/auth'

import LogoCashcon from '../../assets/images/logo-cashcon-inline.png'

const TopHeader = (): JSX.Element => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { 
    user, 
    organizationSelected, 
    canChangeOrganization 
  } = useSelector((state: GlobalState) => ({
    user: state.Y2FzaGNvbg.user,
    organizationSelected: state.Y2FzaGNvbg.organizationSelected,
    canChangeOrganization: state.Y2FzaGNvbg.canChangeOrganization
  }))

  const changeOrganization = (): void => {
    dispatch(Creators.requestOrganizationRelatedList(true))
  }

  const logout = (): void => {
    dispatch(Creators.logout())
  }

  const openMail = (): void => {
    window.open('mailto:suporte@cashcon.com.br')
  }

  return (
    <Grid
      container
      justifyContent='space-between'
      alignItems='center'
      columnSpacing={{ xs: 1, sm: 2 }}
      sx={{
        padding: { xs: 1, lg: '.5rem 3rem' },
        borderBottom: '1px solid #ccc'
      }}
    >
      <Grid container item columnSpacing={{ xs: 2, lg: 4 }} sm='auto'>
        <Grid
          item
          container
          xs='auto'
          justifyContent='center'
          alignItems='center'
        >
          <LinkRouter to='/'>
            <Box
              component='img'
              src={LogoCashcon}
              sx={{
                width: { xs: '150px', sm: '130px', md: '150px' },
                padding: '8px',
                cursor: 'pointer'
              }}
            />
          </LinkRouter>
        </Grid>
        <Grid item xs container justifyContent='space-between'>
          <Grid
            item
            container
            xs='auto'
            justifyContent='flex-start'
            alignContent='center'
          >
            <Box sx={{ display: 'flex' }}>
              <Button
                iconButton
                icon={
                  <HeadsetMic
                    color='secondary'
                    sx={{ fontSize: { xs: '1.6rem', md: '2.2rem' } }}
                  />
                }
                onClickEvent={() => openMail()}
              />
              <Box
                sx={{
                  display: { xs: 'none', md: 'flex' },
                  flexDirection: 'column',
                  justifyContent: 'center'
                }}
              >
                <Text
                  text='Suporte'
                  color='secondary'
                  type='body2'
                  sx={{
                    fontSize: '.7rem',
                    margin: 0
                  }}
                />
                <Link
                  component='a'
                  to='mailto:suporte@cashcon.com.br'
                  text='suporte@cashcon.com.br'
                  underline='always'
                  sx={{
                    fontSize: '.9rem',
                    margin: 0,
                    cursor: 'pointer'
                  }}
                />
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            container
            xs='auto'
            alignItems='center'
            display={{ xs: 'flex', sm: 'none' }}
          >
            <ButtonDropdown id='top-header-menu-xs'>
              <MenuList>
                {
                  organizationSelected && (
                    <MenuItem>
                      <Text
                        text={organizationSelected.name}
                        type='h6'
                        color='secondary'
                        sx={{
                          fontSize: '1.1rem',
                          fontWeight: 600,
                          overflow: "hidden",
                          maxWidth: "300px", 
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                        }}
                      />
                    </MenuItem>
                  )
                }
                {canChangeOrganization && (
                  <MenuItem onClick={() => changeOrganization()}>
                    <Text text='Trocar Organização' />
                  </MenuItem>
                )}
                <MenuItem onClick={() => navigate('/configuracoes')}>
                  <Text text='Configurações'/>
                </MenuItem>
                <MenuItem>
                  <Text text='Meu Perfil' />
                </MenuItem>
                <MenuItem onClick={() => logout()}>
                  <Text text='Sair' />
                </MenuItem>
              </MenuList>
            </ButtonDropdown>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container columnSpacing={{ xs: 2, lg: 4 }} xs='auto'>
        <Grid item xs='auto'>
          <ValueHeader />
        </Grid>
        <Grid
          item
          container
          columnSpacing={{ xs: 2, md: 4 }}
          xs='auto'
          display={{ xs: 'none', sm: 'flex' }}
        >
          <Grid item container xs='auto' alignItems='center'>
            <Box
              sx={{
                width: '1px',
                height: '50px',
                backgroundColor: '#ccc'
              }}
            />
          </Grid>
          <Grid item container xs='auto' display={{ md: 'none' }}>
            <ButtonDropdown id='top-header-menu-sm'>
              <MenuList>
                {
                  organizationSelected && (
                    <MenuItem>
                      <Text
                        text={organizationSelected.name}
                        type='h6'
                        color='secondary'
                        sx={{
                          fontSize: '1.1rem',
                          fontWeight: 600,
                          overflow: "hidden",
                          maxWidth: "300px", 
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                        }}
                      />
                    </MenuItem>
                  )
                }
                {canChangeOrganization && (
                  <MenuItem onClick={() => changeOrganization()}>
                    <Text text='Trocar Organização' />
                  </MenuItem>
                )}
                <MenuItem onClick={() => navigate('/configuracoes')}>
                  <Text text='Configurações'/>
                </MenuItem>
                <MenuItem>
                  <Text text='Meu Perfil' />
                </MenuItem>
                <MenuItem onClick={() => logout()}>
                  <Text text='Sair' />
                </MenuItem>
              </MenuList>
            </ButtonDropdown>
          </Grid>
          <Grid
            item
            container
            spacing={1}
            xs='auto'
            alignItems='center'
            display={{ xs: 'none', md: 'flex' }}
          >
            <Grid item container xs='auto' alignItems='center'>
              <AccountCircle color='secondary' fontSize='large' />
            </Grid>
            <Grid item xs='auto'>
              {
                user && (
                  <Text
                    text={`Olá ${user.name.split(' ')[0]},`}
                    type='body2'
                    sx={{
                      fontSize: '.7rem'
                    }}
                  />
                )
              }
              <Text
                text={organizationSelected ? organizationSelected.name : 'N/A'}
                type='h6'
                color='secondary'
                sx={{
                  fontSize: '0.9rem',
                  fontWeight: 600,
                  overflow: "hidden",
                  maxWidth: "300px", 
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
              />
            </Grid>
            <Grid item container xs='auto' alignItems='center'>
              {canChangeOrganization && (
                <Button
                  iconButton
                  icon={<ChangeCircle />}
                  onClickEvent={() => changeOrganization()}
                />
              )}
              {
                !!organizationSelected?.hasInitialConfiguration && (
                  <Button
                    iconButton
                    icon={<Settings style={{ color: '#4E4E4E' }}/>}
                    onClickEvent={() => navigate('/configuracoes')}
                  />
                )
              }
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs='auto'
            alignItems='center'
            display={{ xs: 'none', md: 'flex' }}
          >
            <Box
              sx={{
                width: '1px',
                height: '50px',
                backgroundColor: '#ccc'
              }}
            />
          </Grid>
          <Grid
            item
            container
            xs='auto'
            alignItems='center'
            display={{ xs: 'none', md: 'flex' }}
          >
            <Button
              type='button'
              variant='text'
              label='Sair'
              onClickEvent={() => logout()}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
export default TopHeader