import { call, takeEvery, all, put } from 'redux-saga/effects';

import requestSaga from '../requestSaga';
import * as Url from '../../../constants/url';
import { Types } from '../../ducks/globalAction/bank';
import SelectOption from '../../../types/SelectOption';

type ResponseCashbackOptions = {
  error: boolean;
  banks: Array<SelectOption>;
};

function* requestBankListOption() {
  yield takeEvery(Types.REQUEST_BANK_LIST_OPTION, function* (action: any) {

    try {

      const { actionCallback } = action

      const response: ResponseCashbackOptions = yield call<any>(
        requestSaga,
        Url.BANKS_LIST_OPTION_URL,
        'post',
        {},
        true,
        false
      );

      yield put(actionCallback(response.banks));
    
    } catch (error) {
      console.error(error)
    }
  });
}

export default function* bankSaga() {
  yield all([requestBankListOption()]);
}
