import React from 'react'
import { Mark } from '@mui/base'
import { Slider, SxProps, Theme } from '@mui/material'
import { useController, useFormContext } from 'react-hook-form'

import './style.css'

type SliderProps = {
  id: string
  name: string
  marks: Array<Mark>
  min: number
  max: number
  sx?: SxProps<Theme>
}

const SliderComponent = (props: SliderProps) => {
  const { id, name, marks, min, max, sx } = props;

  const { control } = useFormContext();
  const { field: { value, onChange } } = useController({
    name,
    control
  })

  React.useEffect(() => {
    onChange(value || min);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Slider
      step={null}
      name={name}
      id={id}
      onChange={(_, value) => {
        onChange(value)
      }}
      value={value}
      track={false}
      marks={marks}
      min={min}
      max={max}
      valueLabelDisplay="auto"
      aria-label='custom-marks'
      valueLabelFormat={(value: number) => `${value.toFixed(1).replace('.', ',')}%`}
      sx={{ ...sx, ml: 1.2, width: { xs: '95%', md: '99%' } }}
    />
  )
}

export default SliderComponent
