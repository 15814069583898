import { call, takeEvery, all, put } from 'redux-saga/effects';

import requestSaga from '../requestSaga';
import * as Url from '../../../constants/url';
import SelectOption from '../../../types/SelectOption'
import { Types } from '../../ducks/globalAction/category';

type ResponseCategoryOptions = {
  error: boolean;
  categories: Array<SelectOption>;
};

function* requestCategoryListOption() {  
  yield takeEvery(Types.REQUEST_CATEGORY_LIST_OPTION, function* (action: any) {

    try {

      const { actionCallback } = action

      const response: ResponseCategoryOptions = yield call<any>(
        requestSaga,
        Url.CATEGORIES_LIST_OPTION_URL,
        'post',
        {},
        true,
        false
      );    
      
      yield put(actionCallback(response.categories))

    } catch (error) {
      console.error(error)
    }
  });
}

export default function* categorySaga() {
  yield all([requestCategoryListOption()]);
}
