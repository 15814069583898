import * as React from 'react'
import { SxProps, Theme } from '@mui/material/styles'
import { Button, IconButton, CircularProgress } from '@mui/material'

type ButtonProps = {
  label?: string
  type?: 'button' | 'submit'
  variant?: 'contained' | 'text' | 'outlined'
  iconButton?: boolean
  icon?: React.ReactNode
  endIcon?: React.ReactNode
  component?: 'span' | 'button'
  fullWidth?: boolean
  onClickEvent?: (e: React.MouseEvent) => void
  loading?: boolean
  disabled?: boolean
  sx?: SxProps<Theme>
}

const ButtonComponent = (props: ButtonProps): JSX.Element => {

  const { 
    label, 
    type = 'submit',
    variant = 'contained',
    iconButton = false, 
    icon,
    endIcon,
    component = 'button', 
    fullWidth, 
    onClickEvent,
    loading = false,
    disabled = false,
    sx 
  } = props



  const buttonEvent = (e: React.MouseEvent): void => {
    if(onClickEvent) onClickEvent(e)
  }

  if (iconButton)
    return <IconButton color="primary" component="span" onClick={buttonEvent} sx={sx}>
      {icon}
    </IconButton>

  if (type === 'button')
      return <Button 
      variant={variant} 
      type={type}
      component={component} 
      fullWidth={fullWidth}
      endIcon={endIcon}
      onClick={buttonEvent}
      disabled={loading ? true : disabled}
      sx={sx}
    >
      {loading ? <CircularProgress /> : label}
    </Button>

  return <Button 
    variant={variant} 
    type={type}
    component={component} 
    fullWidth={fullWidth}
    endIcon={endIcon}
    disabled={loading ? true : disabled}
    sx={sx}
  >
    {loading ? <CircularProgress /> : label}
  </Button>
};

export default ButtonComponent;