import { createActions, createReducer } from 'reduxsauce'

import ExtractState from '../../types/ExtractState'

const INITIAL_STATE: ExtractState = {
  totalBalance: 0,
  totalPendingBalance: 0,
  listExtract: [],
  extractPage: 1,
  hasMoreExtractPages: true,
  organizationListOption: [],
  extractPdfDisabledButton: false,
}

export const { Types, Creators } = createActions({
  resetExtract: null,
  setListExtract: ['listExtract'],
  resetExtractPage: ['extractPage'],
  setNextExtractPage: null,
  resetExtractBalance: null,
  setExtractPdfDisabledButton: ['disabled'],
  setExtractTotalBalance: ['totalBalance'],
  setHasMoreExtractPages: ['hasMorePages'],
  setExtractOrganizationListOption: ['organizationListOption'],
  setExtractTotalPendingBalance: ['totalPendingBalance'],

  // Sagas
  requestExtractFilter: ['extractFilter', 'appendValue'],
  requestNextExtractPage: null,
  requestExtractPdf: ['extractFilter']
})

const resetExtract = (): ExtractState => ({ ...INITIAL_STATE })

const setListExtract = (state = INITIAL_STATE, action: any): ExtractState => 
  ({ ...state, listExtract: action.listExtract })

const resetExtractPage = (state = INITIAL_STATE): ExtractState => 
  ({ ...state, extractPage: 1 })

const setNextExtractPage = (state = INITIAL_STATE): ExtractState => 
  ({ ...state, extractPage: state.extractPage + 1 })

const resetExtractBalance = (state = INITIAL_STATE): ExtractState => 
  ({ ...state, totalBalance: 0, totalPendingBalance: 0 })

const setExtractTotalBalance = (state = INITIAL_STATE, action: any): ExtractState => 
  ({ ...state, totalBalance: action.totalBalance })

const setExtractTotalPendingBalance = (state = INITIAL_STATE, action: any): ExtractState => 
  ({ ...state, totalPendingBalance: action.totalPendingBalance })

const setHasMoreExtractPages = (state = INITIAL_STATE, action: any): ExtractState => 
  ({ ...state, hasMoreExtractPages: action.hasMorePages })

const setExtractPdfDisabledButton = (state = INITIAL_STATE, action: any): ExtractState => 
({ ...state, extractPdfDisabledButton: action.disabled })

const setExtractOrganizationListOption = (state = INITIAL_STATE, action: any): ExtractState => 
  ({ ...state, organizationListOption: action.organizationListOption })

const HANDLERS = {
  [Types.RESET_EXTRACT]: resetExtract,
  [Types.SET_LIST_EXTRACT]: setListExtract,
  [Types.RESET_EXTRACT_PAGE]: resetExtractPage,
  [Types.SET_NEXT_EXTRACT_PAGE]: setNextExtractPage,
  [Types.RESET_EXTRACT_BALANCE]: resetExtractBalance,
  [Types.SET_EXTRACT_TOTAL_BALANCE]: setExtractTotalBalance,
  [Types.SET_HAS_MORE_EXTRACT_PAGES]: setHasMoreExtractPages,
  [Types.SET_EXTRACT_PDF_DISABLED_BUTTON]: setExtractPdfDisabledButton,
  [Types.SET_EXTRACT_TOTAL_PENDING_BALANCE]: setExtractTotalPendingBalance,
  [Types.SET_EXTRACT_ORGANIZATION_LIST_OPTION]: setExtractOrganizationListOption
}

export default createReducer(INITIAL_STATE, HANDLERS)