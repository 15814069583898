import { Routes, Route, Navigate } from 'react-router-dom'

import {
  ForgotPassword,
  Login,
  Register,
  RegisterForm,
  ResetPassword,
  TextDoc
} from './pageList'

const NonAuthRoutes = (): JSX.Element => {
  return <Routes>
    <Route path='/login' element={<Login />} />
    <Route path='/registrar' element={<Register />} />
    <Route path='/registrar/:type' element={<RegisterForm />} />
    <Route path='/doc/text' element={<TextDoc />} />
    <Route path='/recuperar-senha' element={<ForgotPassword />} />
    <Route path='/redefinir-senha/:token' element={<ResetPassword />} />
    <Route path='*' element={<Navigate to='/login' replace />} />
  </Routes>
}

export default NonAuthRoutes
