import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';

import {
  ThemeWrapper,
  Dashboard,
  NetworkCompanies,
  Extracts,
  Notifications,
  Settings,
  NotFound,
  SettingsCashback,
  CompanyInitialSetting,
  SettingsBankDataCondominium,
  CondominiumInitialSetting,
  ProfileOrganization,
  ProfileUser,
  AccountNonActive,
  Condominiums,
  ValidateAccount,
} from '../routes/pageList';
import GlobalState from '../types/GlobalState';
import { COMPANY, CONDOMINIUM } from '../constants/organizationType';
import OrganizationStatusType from '../enum/organizationStatus';
import { Creators } from '../store/ducks/auth';

const AuthRoutes = (): JSX.Element => {
  const { organizationSelected, organizationRelatedList, user, mandateExpired, active } = useSelector((state: GlobalState) => ({
    organizationSelected: state.Y2FzaGNvbg.organizationSelected,
    organizationRelatedList: state.Y2FzaGNvbg.organizationRelatedList,
    user: state.Y2FzaGNvbg.user,
    mandateExpired: state.Y2FzaGNvbg.mandateExpired,
    active: state.Y2FzaGNvbg.active ?? null
  }));

  console.log("active", active);

  const dispatch = useDispatch();

  React.useEffect(() => {
    if ( organizationSelected && !organizationSelected?.hasOwnProperty('status')) {
      dispatch(Creators.logout());
    }

    if (organizationSelected && !user?.hasOwnProperty('validatedAccount')) {
      dispatch(Creators.logout());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationSelected]);
  
  React.useEffect(() => {
    if (organizationSelected && organizationSelected.status !== OrganizationStatusType.ACTIVE ) {
      dispatch(Creators.requestOrganizationStatus());
    }
    dispatch(Creators.requestOrganizationRelatedList(false));
    
    dispatch(Creators.requestUserMandate());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);  

  let location = useLocation();
  React.useEffect(() => {
    console.log(location)
    dispatch(Creators.requestActive());
  }, [location, dispatch]);

  React.useEffect(() => {
    if (active === 0) {
      dispatch(Creators.logout());
    }
  }, [active, dispatch]);

  let initialPage : React.ReactElement | null = null
  if (mandateExpired) {
    initialPage = (<Condominiums />)
  } else if (!user?.validatedAccount) {
    initialPage = (<ValidateAccount />) // se tiver usuário com conta não validada
  } else if (organizationSelected && organizationSelected?.status !== OrganizationStatusType.ACTIVE) {
    initialPage = (<AccountNonActive status={organizationSelected?.status}/>)
  } else if (!organizationSelected?.hasInitialConfiguration) {
    if (organizationSelected?.type === COMPANY) { 
      initialPage = (<CompanyInitialSetting />)
    } else if (!organizationSelected && !organizationRelatedList.length) {  
      initialPage = (<Condominiums />);
    } else {
      initialPage = (<CondominiumInitialSetting />);
    } 
  }  

  return (
    <Routes>
      <Route path="/" element={<ThemeWrapper />}>
        {typeof mandateExpired === "boolean" && initialPage ? (
          <Route path="/" element={initialPage} />
        ) : typeof mandateExpired === "boolean" && mandateExpired === false && (
          <>
            <Route index element={<Dashboard />} />
            {organizationSelected && organizationSelected.type === 1 && (
              <Route path="rede-empresas" element={<NetworkCompanies />} />
            )}

            {/* <Route path='vendas' element={<Sales />} /> */}
            <Route path="extratos" element={<Extracts />} />
            <Route path="notificacoes" element={<Notifications />} />
            <Route path="configuracoes" element={<Settings />} />
            {organizationSelected && organizationSelected.type === COMPANY && (
              <Route
                path="configuracoes/cashback"
                element={<SettingsCashback />}
              />
            )}
            {organizationSelected &&
              organizationSelected.type === CONDOMINIUM && (
                <Route
                  path="configuracoes/dados-bancarios"
                  element={<SettingsBankDataCondominium />}
                />
              )}
            <Route
              path="configuracoes/perfil-organizacao"
              element={<ProfileOrganization />}
            />
            <Route
              path="configuracoes/perfil-usuario"
              element={<ProfileUser />}
            />
            {organizationSelected &&
              organizationSelected.type === CONDOMINIUM && (
                <Route
                  path="configuracoes/dados-bancarios"
                  element={<SettingsBankDataCondominium />}
                />
              )}
            {organizationSelected &&
              organizationSelected.type === CONDOMINIUM && (
                <Route
                  path="configuracoes/condominios"
                  element={<Condominiums />}
                />
              )}
            <Route
              path="configuracoes/perfil-organizacao"
              element={<ProfileOrganization />}
            />
            <Route
              path="configuracoes/perfil-usuario"
              element={<ProfileUser />}
            />
          </>
        )}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AuthRoutes;
