import { takeEvery, call, put, all, select } from 'redux-saga/effects'

import toast from '../../utils/toast'
import requestSaga from './requestSaga'
import * as Url from '../../constants/url'
import AuthState from '../../types/AuthState'
import ToastType from '../../constants/toastType'
import Organization from '../../types/Organization'
import { Creators as SaleCreators } from '../ducks/sale'
import { Types, Creators as AuthCreators } from '../ducks/auth'
import OrganizationSelected from '../../types/OrganizationSelected'
import GlobalState from '../../types/GlobalState'

type LoginResponse = {
  error: boolean
  type: string
  message: string
  data: any
}

// ToDo: Avaliar apagar
type ResponseOrganizationList = {
  error: boolean
  organizations: Array<Organization>
}

type ResponseSetOrganizationSelected = {
  error: boolean
  organizationSelected: OrganizationSelected
}

type ResponseOrganizationStatus = {
  error: boolean
  status: number
}

type ResponseRequestMandate = {
  error: false,
      type: string,
      message: string,
      data: any,
}

type ResponseRequestActive = {
  error: false,
  status: number
  type: number,
  message: string,
  data: any,
}
// ToDo: Remover essas funções
const hasError = (response: any): boolean => response.error
const parseUser = (response: any): any => ({
  name: response.name,
  isRoot: response.isRoot,
  token: response.token,
  refreshToken: response.refreshToken,
  validatedAccount: response.validatedAccount,
  mandateExpired: response.mandateExpired
})
const parseOrganizationSelected = (response: any): OrganizationSelected => response.organizationSelected
const parseCanChangeOrganization = (response: any): boolean => response.canChangeOrganization

function* requestUserLogin() {
  yield takeEvery(Types.REQUEST_USER_LOGIN, function* (action: any) {

    try {

      yield put(AuthCreators.setIsLoginLoading(true))
      
      const response: LoginResponse = yield call(
        requestSaga, 
        Url.LOGIN_URL, 
        'post', 
        action.userCredentials, 
        false,
        false
      )

      if (hasError(response)) {

        toast(ToastType.ERROR, response.message)
        yield put(AuthCreators.resetUser())

      } else {
        const user = parseUser(response.data)

        const organizationSelected = parseOrganizationSelected(response.data)
        const canChangeOrganization = parseCanChangeOrganization(response.data)

        yield put(AuthCreators.setUser(user))
        yield put(AuthCreators.setOrganizationSelected(organizationSelected))
        yield put(AuthCreators.setCanChangeOrganization(canChangeOrganization))
      }
  
    } catch (error) {
      console.error(error)
      toast(ToastType.ERROR, 'Ops! Houve um erro ao efetuar login')
    } finally {
      yield put(AuthCreators.setIsLoginLoading(false))
    }
  })
}

function* requestUserMandate() {
  yield takeEvery(Types.REQUEST_USER_MANDATE, function* () {

    try {

      const response: ResponseRequestMandate = yield call(
        requestSaga, 
        Url.REFRESH_MANDATE, 
        'get', 
        {},
        true,
        false
      )

      if (response && !response.error) {
        yield put(AuthCreators.setMandateExpired(response.data))
      } else {
        toast(ToastType.ERROR, response.message)
      }
  
    } catch (error) {
      console.error(error)
      toast(ToastType.ERROR, 'Ops! Houve um erro ao efetuar login')
    } 
  })
}

function* requestActive() {
  yield takeEvery(Types.REQUEST_ACTIVE, function* () {

    try {

      const response: ResponseRequestActive = yield call(
        requestSaga, 
        Url.CHANGE_ACTIVE, // alterar para GET_ACTIVE pois não está sendo feito nenhuma alteração (CHANGE)
        'get', 
        {},
        true,
        false
      )

      console.log("test response", response)
            
      if (response && !response.error) {
        yield put(AuthCreators.setActive(response.data))
      } else {
        toast(ToastType.ERROR, response.message)
      }
  
    } catch (error) {
      console.error(error)
      toast(ToastType.ERROR, 'Ops! Houve um erro ao efetuar login')
    } 
  })
}

// ToDo: Avaliar colocar no arquivo Global já que esse request apenas traz a opções "listOption"
function* requestOrganizationRelatedList() {
    yield takeEvery(Types.REQUEST_ORGANIZATION_RELATED_LIST, function* (action: any) {
      
      try {

        const response: ResponseOrganizationList = 
        yield call<any>(requestSaga, Url.ORGANIZATION_RELATED_LIST_URL, 'get', null, true, false)
        
        if(response && !response.error) {
          yield put(AuthCreators.setOrganizationRelatedList(response.organizations))
          yield put(AuthCreators.setShowOrganizationSelectDialog(action.showOrganizationSelectDialog))
          
          yield put(AuthCreators.setCanChangeOrganization(response.organizations.length > 1))

          if (response.organizations.length === 1) 
            yield put(AuthCreators.setOrganizationSelected(response.organizations[0]))

        } else {
          toast(ToastType.ERROR, 'Falha ao obter lista de organizações')
        }

        if (action.callbackAction) {
          action.callbackAction();
        }
      } catch (error) {
        console.error(error)
      }
    })
}

function* requestSetOrganizationSelected() {
  yield takeEvery(Types.REQUEST_SET_ORGANIZATION_SELECTED, function* (action: any) {

    try {

      const response: ResponseSetOrganizationSelected = 
        yield call<any>(
          requestSaga, 
          Url.SET_ORGANIZATION_SELECTED_URL, 
          'post', 
          {
            organizationId: action.organizationId
          }, 
          true, 
          false
        )
      
      if(response.error) {
        toast(ToastType.ERROR, 'Falha ao selecionar organização')
        return
      }

      yield put(AuthCreators.setOrganizationSelected(response.organizationSelected))
      yield put(AuthCreators.setShowOrganizationSelectDialog(false))
      yield put(SaleCreators.requestLastSales())
      yield put(SaleCreators.requestBalance())

    } catch (error) {
      console.error(error)
    }
  })
}

function* requestOrganizationStatus() {
  yield takeEvery(Types.REQUEST_ORGANIZATION_STATUS, function* () {
    
    try {

    const response: ResponseOrganizationStatus = 
      yield call<any>(
        requestSaga, 
        Url.ORGANIZATION_STATUS_URL, 
        'get',
        null,
        true, 
        false
      )
    
    if(response && !response.error) {
      yield put(AuthCreators.setOrganizationStatus(response.status))
    } else {

      toast(ToastType.ERROR, 'Falha atualizar situação da conta')
    }

  } catch (error) {
    console.error(error)
  }
  })
  
}

function* logout() {
  yield takeEvery(Types.LOGOUT, function* () {
    
    try {

      const { showUserBalance }: AuthState = yield select((state: GlobalState) => state.Y2FzaGNvbg)

      yield call(requestSaga, Url.LOGOUT_URL, 'get', null, false, false)

      yield put(AuthCreators.resetAuth(showUserBalance))

    } catch (error) {
      console.error(error)
    }
  });
}

export default function* login() {
  yield all([
    logout(),
    requestActive(),
    requestUserLogin(),
    requestUserMandate(),
    requestOrganizationStatus(),
    requestOrganizationRelatedList(),
    requestSetOrganizationSelected()
  ])
}