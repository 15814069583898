import { useSelector } from 'react-redux'
import { ThemeProvider } from '@mui/material/styles'
import { BrowserRouter } from 'react-router-dom'

import theme from '../styles/theme'
import AuthRoutes from './AuthRoutes'
import NonAuthRoutes from './NonAuthRoutes'

const Container = (): JSX.Element => {

  const { user } = useSelector((state: any) => state.Y2FzaGNvbg)

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        {
          user ? <AuthRoutes /> : <NonAuthRoutes />
        }
      </BrowserRouter>
    </ThemeProvider>
  )
}

export default Container