const FILTER_PREFIX = '/filter'
const SESSION_PREFIX = '/session'
const COMPANY_PREFIX = '/company'
const ADMINISTRATORS_PREFIX = '/administrators'
const COMPANIES_PREFIX = '/companies'
const CASHBACKS_PREFIX = '/cashbacks'
const CATEGORIES_PREFIX = '/categories'
const LIST_OPTION_PREFIX = '/listOption'
const INDICATIONS_PREFIX = '/indications'
const SETTINGS_CASHBACK_INITIAL_PREFIX = '/settingsCashbackInitial'
const SETTINGS_CONDOMINIUM_INITIAL_PREFIX = '/settingsCondominiumInitial'
const SETTINGS_CASHBACK = '/settingsCashback'
const SETTINGS_BANK_DATA = '/settingsBankData'
const SETTINGS_CONDOMINIUMS_PREFIX = '/settingsCondominiums'
const NETWORK_COMPANIES_PREFIX = '/networkCompanies'
const SUBCATEGORIES_PREFIX = '/subcategories'
const CONDOMINIUM_PREFIX = '/condominium'
const CONDOMINIUMS_PREFIX = '/condominiums'
const ORGANIZATIONS_PREFIX = '/organizations'
const CITIES_PREFIX = '/cities'
const BANKS_PREFIX = '/banks'
const SALES_PREFIX = '/sales'
const EXTRACT_PREFIX = '/extracts'
const ORGANIZATION_STATUS_PREFIX = '/organizationStatus'
const VALIDATE_ACCOUNT_PREFIX = '/validateAccount'
const RELATED_ORGANIZATIONS_PREFIX = '/relatedOrganizations'
const RELATED_CONDOMINIUMS_PREFIX = '/relatedCondominiums'
const SET_ORGANIZATION_SELECTED_PREFIX = '/setOrganizationSelected'
const PROFILE_ORGANIZATION_PREFIX = '/profileOrganization'
const PROFILE_USER_PREFIX = '/profileUser'

export const LOGIN_URL = `${SESSION_PREFIX}/login`
export const REFRESH_MANDATE = `${SESSION_PREFIX}/refresh-mandate`
export const CHANGE_ACTIVE = `${SESSION_PREFIX}/active`
export const LOGOUT_URL = `${SESSION_PREFIX}/logout`
export const REFRESH_TOKEN_URL = '/refresh'
export const FORGOT_PASSWORD_URL = `${SESSION_PREFIX}/password`
export const RESET_PASSWORD_URL = `${SESSION_PREFIX}/password-reset`
export const VERIFY_PASSWORD_URL = `${SESSION_PREFIX}/password-verify`

export const REGISTER_COMPANY_URL = '/register/company'
export const REGISTER_CONDOMINIUM_URL = '/register/condominium'

// Common Routes
export const EXTRACT_PDF = `${EXTRACT_PREFIX}/getPdf`
export const VALIDATE_ACCOUNT_URL = VALIDATE_ACCOUNT_PREFIX
export const VALIDATE_ACCOUNT_SEND_CODE_URL = `${VALIDATE_ACCOUNT_PREFIX}/sendCode`
export const VALIDATE_ACCOUNT_GET_LAST_SENT_CODE = `${VALIDATE_ACCOUNT_PREFIX}/getLastSentCode`
export const CATEGORIES_FILTER_URL = `${CATEGORIES_PREFIX}${FILTER_PREFIX}`
export const BANKS_LIST_OPTION_URL = `${BANKS_PREFIX}${LIST_OPTION_PREFIX}`
export const CITIES_LIST_OPTION_URL = `${CITIES_PREFIX}${LIST_OPTION_PREFIX}`
export const SUBCATEGORIES_FILTER_URL = `${SUBCATEGORIES_PREFIX}${FILTER_PREFIX}`
export const CATEGORIES_LIST_OPTION_URL = `${CATEGORIES_PREFIX}${LIST_OPTION_PREFIX}`
export const SUBCATEGORIES_LIST_OPTION_URL = `${SUBCATEGORIES_PREFIX}${LIST_OPTION_PREFIX}`
export const ORGANIZATION_STATUS_URL = `${ORGANIZATIONS_PREFIX}${ORGANIZATION_STATUS_PREFIX}`
export const CONDOMINIUM_RELATED_LIST_URL = `${ORGANIZATIONS_PREFIX}${RELATED_CONDOMINIUMS_PREFIX}`
export const ORGANIZATION_RELATED_LIST_URL = `${ORGANIZATIONS_PREFIX}${RELATED_ORGANIZATIONS_PREFIX}`
export const SET_ORGANIZATION_SELECTED_URL = `${ORGANIZATIONS_PREFIX}${SET_ORGANIZATION_SELECTED_PREFIX}`
export const PROFILE_ORGANIZATION_URL = `${PROFILE_ORGANIZATION_PREFIX}`
export const PROFILE_USER_URL = `${PROFILE_USER_PREFIX}`

// Condominium Routes
export const DASHBOARD_URL = `${CONDOMINIUM_PREFIX}/dashboard`
export const INDICATIONS_COMPANY_URL = `${CONDOMINIUM_PREFIX}${INDICATIONS_PREFIX}` 
export const SETTINGS_BANK_DATA_URL = `${CONDOMINIUM_PREFIX}${SETTINGS_BANK_DATA}`
export const SETTINGS_CONDOMINIUM_INITIAL_URL = `${CONDOMINIUM_PREFIX}${SETTINGS_CONDOMINIUM_INITIAL_PREFIX}`
export const SETTINGS_CONDOMINIUM_URL = `${CONDOMINIUM_PREFIX}${SETTINGS_CONDOMINIUMS_PREFIX}`
export const SETTINGS_CONDOMINIUM_REGISTER_CONDOMINIUM_URL = `${CONDOMINIUM_PREFIX}${SETTINGS_CONDOMINIUMS_PREFIX}/register`
export const SETTINGS_CONDOMINIUM_EDIT_CONDOMINIUM_URL = `${CONDOMINIUM_PREFIX}${SETTINGS_CONDOMINIUMS_PREFIX}/edit`
export const ADMINISTRATOR_FILTER_URL = `${CONDOMINIUM_PREFIX}${ADMINISTRATORS_PREFIX}${FILTER_PREFIX}`
export const COMPANY_FILTER_URL = `${CONDOMINIUM_PREFIX}${COMPANIES_PREFIX}${FILTER_PREFIX}`
export const EXTRACTS_CONDOMINIUM_URL = `${CONDOMINIUM_PREFIX}${EXTRACT_PREFIX}${FILTER_PREFIX}`
export const NETWORK_COMPANIES_FILTER_URL = `${CONDOMINIUM_PREFIX}${NETWORK_COMPANIES_PREFIX}${FILTER_PREFIX}`
export const SALES_TOTAL_CONDOMINIUM_BALANCE_MONTH = `${CONDOMINIUM_PREFIX}${SALES_PREFIX}/totalBalanceMonth`
export const ADMINISTRATOR_CONDOMINIUM_LIST_OPTION_URL = `${CONDOMINIUM_PREFIX}${ADMINISTRATORS_PREFIX}${LIST_OPTION_PREFIX}`
export const COMPANY_CONDOMINIUM_LIST_OPTION_URL = `${CONDOMINIUM_PREFIX}${COMPANIES_PREFIX}${LIST_OPTION_PREFIX}`
export const CONDOMINIUM_COMPANY_CATEGORY_LIST_OPTION_URL = `${CONDOMINIUM_PREFIX}${NETWORK_COMPANIES_PREFIX}${LIST_OPTION_PREFIX}`

// Company Routes
export const PLANS_URL = `${COMPANY_PREFIX}/plans`
export const SALES_COMPANY_URL = `${COMPANY_PREFIX}${SALES_PREFIX}`
export const EXTRACTS_COMPANY_URL = `${COMPANY_PREFIX}${EXTRACT_PREFIX}${FILTER_PREFIX}`
export const SALES_LAST_RELEASES_COMPANY = `${COMPANY_PREFIX}${EXTRACT_PREFIX}/lastReleases`
export const SALES_TOTAL_COMPANY_BALANCE_MONTH = `${COMPANY_PREFIX}${SALES_PREFIX}/totalBalanceMonth`
export const CONDOMINIUMS_COMPANY_FILTER_URL = `${COMPANY_PREFIX}${CONDOMINIUMS_PREFIX}${FILTER_PREFIX}`
export const CONDOMINIUMS_COMPANY_LIST_OPTION_URL = `${COMPANY_PREFIX}${CONDOMINIUMS_PREFIX}${LIST_OPTION_PREFIX}`
export const CASHBACK_OPTIONS_COMPANY_URL = `${COMPANY_PREFIX}${CASHBACKS_PREFIX}${LIST_OPTION_PREFIX}`
export const SETTINGS_CASHBACK_INITIAL_COMPANY_CASHBACK_URL = `${COMPANY_PREFIX}${SETTINGS_CASHBACK_INITIAL_PREFIX}`
export const SETTINGS_CASHBACK_COMPANY_CASHBACK_URL = `${COMPANY_PREFIX}${SETTINGS_CASHBACK}`