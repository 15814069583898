import { useSelector } from 'react-redux'
import { Bar, XAxis, YAxis, BarChart, ResponsiveContainer } from 'recharts'

import Text from '../Text'

import GlobalState from '../../types/GlobalState'
import { formatNumberToCurrency } from '../../utils/formatString'

type ChartProps = {
  data: Array<any>
}

// ToDo: Pegar da paleta de cores do tema do Material UI
const palette = { primary: '#009132', secondary: '#003e9e' };

const Chart = ({ data }: ChartProps) => {

  const showUserBalance: boolean = useSelector((state: GlobalState): boolean => state.Y2FzaGNvbg.showUserBalance)

  if(!data || data.length === 0)
    return <Text 
      text='Não há dados para os últimos 12 meses'
      align='center'
      type='h6'
    />

  return (
    <ResponsiveContainer width="100%" minHeight="200px">
      <BarChart
        height={150}
        barSize={8}
        margin={{ top: 20, left: 20 }}
        data={data}
      >
        <XAxis
          dataKey="name"
          tick={{ stroke: palette.secondary, strokeWidth: 0.4, fontSize: 12 }}
          padding={{ left: 20, right: 30 }}
        />
        {
          showUserBalance && (
            <YAxis
              tickFormatter={formatNumberToCurrency}
              tick={{
                stroke: palette.primary,
                strokeWidth: 0.4,
                fontSize: 12
              }}
              label={{
                value: 'R$',
                fontWeight: '600',
                position: 'right',
                fontSize: 10,
                fill: palette.secondary
              }}
            />
          )
        }
        <Bar dataKey="value" fill={palette.primary} />
      </BarChart>
    </ResponsiveContainer>
  )
}

export default Chart
