import { Typography } from '@mui/material'
import { SxProps, Theme } from '@mui/material/styles'
import { TypographyTypeMap } from '@mui/material/Typography'
import { CSSProperties } from 'react'

type TextProps = {
  /**
   * Type of text
   * Values: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'subtitle1' | 'subtitle2' | 'body1' | 'body2'
   */
  type?: TypographyTypeMap['props']['variant']
  align?: TypographyTypeMap['props']['align']
  text: string
  icon?: React.ReactElement 
  iconStyle?: CSSProperties
  color?: string 
  sx?: SxProps<Theme>
} 

const TextComponent = (props: TextProps): JSX.Element => {

  const { type = 'body2', align, color, icon, iconStyle, text, sx } = props

  return <Typography 
    variant={type}
    align={align} 
    color={color} 
    sx={sx}
  >
    {text}
    <span style={iconStyle}>
      {icon}
    </span>
  </Typography>
}

export default TextComponent