import { createActions, createReducer } from 'reduxsauce';
import IndicationState from '../../types/IndicationState';

const INITIAL_STATE = {
  openDialog: false
}

export const { Types, Creators } = createActions({
  setOpenDialog: ['openDialog'],

  // Sagas
  requestIndicationCompanySubmit: ['data'],
});

const setOpenDialog = (state = INITIAL_STATE, action: any): IndicationState => 
  ({ ...state, openDialog: action.openDialog })


const HANDLERS = {
  [Types.SET_OPEN_DIALOG]: setOpenDialog
};

export default createReducer(INITIAL_STATE, HANDLERS);
