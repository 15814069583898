import { all, call, put, takeEvery } from 'redux-saga/effects';

import { 
  SETTINGS_BANK_DATA_URL, 
  SETTINGS_CONDOMINIUM_INITIAL_URL 
} from '../../constants/url';
import toast from '../../utils/toast';
import requestSaga from './requestSaga';
import ToastType from '../../constants/toastType';
import { Types, Creators } from '../ducks/settingBankData';
import SettingBankData from '../../types/SettingBankData';

type ResponseSettingBankData = {
  error: false;
  settingBankData: SettingBankData;
};

type ResponseSettingBankDataCondominiumSubmit = {
  error: boolean;
  type: string;
  message: string;
};

function* requestSettingBankDataCondominium() {
  yield takeEvery(Types.REQUEST_SETTING_BANK_DATA_CONDOMINIUM, function* () {
    
    try {

      const response: ResponseSettingBankData = yield call<any>(
        requestSaga,
        SETTINGS_BANK_DATA_URL,
        'get',
        {},
        true,
        false
      );

      if (response.error)
        toast(ToastType.ERROR, 'Falha ao carregar dados bancários');

      yield put(Creators.setSettingBankDataCondominium(response.settingBankData));

    } catch (error) {
      console.error(error)
    }
  });
}

function* requestSettingBankDataCondominiumSubmit() {
  yield takeEvery(
    Types.REQUEST_SETTING_BANK_DATA_CONDOMINIUM_SUBMIT,
    function* (action: any) {

      try {

        const response: ResponseSettingBankDataCondominiumSubmit =
          yield call<any>(
            requestSaga,
            SETTINGS_BANK_DATA_URL,
            'post',
            action.settingBankDataCondominium,
            true,
            false
          );

        if (response.error) toast(ToastType.ERROR, response.message);
        else toast(ToastType.SUCCESS, response.message);

        yield put(Creators.requestSettingBankDataCondominium());

      } catch (error) {
        console.error(error)
      }
    }
  );
}

// ToDo: Avaliar unificar com o método de cima
function* requestSettingInitialBankDataCondominiumSubmit() {
  yield takeEvery(
    Types.REQUEST_SETTING_INITIAL_BANK_DATA_CONDOMINIUM_SUBMIT,
    function* (action: any) {

      try {

        const response: ResponseSettingBankDataCondominiumSubmit =
          yield call<any>(
            requestSaga,
            SETTINGS_CONDOMINIUM_INITIAL_URL,
            'post',
            action.settingBankDataCondominium,
            true,
            false
          );

        if (response.error) {
          toast(ToastType.ERROR, response.message);
          return
        }

        toast(ToastType.SUCCESS, response.message);
        yield put(Creators.setShowSuccessInitialSetting(true));

      } catch (error) {
        console.error(error)
      }
    }
  );
}

export default function* settingBankDataCondominiumSaga() {
  yield all([
    requestSettingBankDataCondominium(),
    requestSettingBankDataCondominiumSubmit(),
    requestSettingInitialBankDataCondominiumSubmit()
  ]);
}
