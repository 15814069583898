import { call, takeEvery, all, put } from 'redux-saga/effects';

import requestSaga from '../requestSaga';
import * as Url from '../../../constants/url';
import CheckboxOption from '../../../types/CheckboxOption';
import { Types } from '../../ducks/globalAction/subcategory';

type ResponseSubcategoryOptions = {
  error: boolean;
  subcategories: Array<CheckboxOption>;
};

function* requestSubcategoryListOption() {
  yield takeEvery(Types.REQUEST_SUBCATEGORY_LIST_OPTION, function* (action: any) {

    try {

      const { categoryId, typeResponse, actionCallback } = action

      const response: ResponseSubcategoryOptions = yield call<any>(
        requestSaga,
        Url.SUBCATEGORIES_LIST_OPTION_URL,
        'post',
        {
          categoryId,
          typeResponse
        },
        true,
        false
      );

      yield put(actionCallback(response.subcategories))

    } catch (error) {
      console.error(error)
    }

  });
}

export default function* subcategorySaga() {
  yield all([requestSubcategoryListOption()]);
}
