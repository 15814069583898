import { SxProps, Theme } from '@mui/material/styles'
import Link, { LinkTypeMap } from '@mui/material/Link'
import { TypographyTypeMap } from '@mui/material/Typography'

type LinkProps = {
  to?: string
  type?: TypographyTypeMap['props']['variant']
  component?: 'a' | 'button'
  color?: string
  text: string
  underline?: LinkTypeMap['props']['underline']
  disabled?: boolean
  sx?: SxProps<Theme>
  BeforeIcon?: JSX.Element
  AfterIcon?: JSX.Element
  onClickEvent?: () => void
}

const LinkComponent = (props: LinkProps): JSX.Element => {

  const { 
    to, 
    type = 'body1', 
    component = 'a', 
    color, 
    text,
    underline = 'none',
    disabled = false,
    onClickEvent,
    sx, 
    BeforeIcon, 
    AfterIcon 
  } = props

  const handleClick = (): void => {
    if (onClickEvent)
      onClickEvent()
  }

  return <Link
    component={component}
    href={to} 
    variant={type} 
    underline={underline}
    disabled={disabled}
    color={color}
    onClick={() => handleClick()}
    sx={sx}
  >
    {
      BeforeIcon
    }
    {text}
    {
      AfterIcon
    }
  </Link>
};

export default LinkComponent;