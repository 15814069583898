import React from 'react';
import {
  Avatar,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import { Add, Visibility } from '@mui/icons-material';

import { Column } from '../../types/Table';
import { Button } from '..';

interface TableProps {
  title: string;
  columns: Column[];
  data: any[];
  showAddButton?: boolean;
  onClickAddButton?: () => void;
  showDetailColumn?: boolean;
  onClickDetailButton?: (secureId: string) => void;
}

interface AddButtonProps {
  show: boolean;
  onClick?: () => void;
}

const AddButton = ({ show, onClick }: AddButtonProps) => {
  return (
    <>
      {show && (
        <Grid item xs="auto" ml="auto" onClick={onClick}>
          <Avatar
            sx={{
              bgcolor: ({ palette }) => palette.secondary.main,
              cursor: 'pointer',
            }}
          >
            <Add />
          </Avatar>
        </Grid>
      )}
    </>
  );
};

interface DetailColumnProps {
  onClick: (e: React.MouseEvent) => void;
}

const DetailColumn = ({ onClick }: DetailColumnProps): JSX.Element => (
  <Button iconButton onClickEvent={onClick} icon={<Visibility />} />
);

const TableComponent = (props: TableProps) => {
  const {
    columns: dynamicColumns,
    data,
    title,
    showAddButton = true,
    onClickAddButton,
    showDetailColumn,
    onClickDetailButton = () => {},
  } = props;

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const fixedColumns: Column[] = [];

  const detailKey = 'detail-htd9siw3';
  if (showDetailColumn)
    fixedColumns.push({
      name: detailKey,
      label: 'Detalhes',
      customRender: () => (
        <DetailColumn
          onClick={(e: React.MouseEvent) =>
            onClickDetailButton(
              e.currentTarget.parentElement?.parentElement?.id || ''
            )
          }
        />
      ),
    });

  const columns = [...dynamicColumns, ...fixedColumns];

  return (
    <Grid item container spacing={2}>
      <Grid item container xs={12}>
        <Grid item xs="auto" sx={{ my: 'auto' }}>
          <Typography
            sx={{ flex: '1 1 100%' }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            {title}
          </Typography>
        </Grid>
        <AddButton show={showAddButton} onClick={onClickAddButton} />
      </Grid>
      <Grid item xs={12} sx={{ width: '100%', overflow: 'auto' }}>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell key={column.name + index}>
                  <Typography variant="subtitle1" fontSize={14} fontWeight="600">
                    {column.label}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((item, index) => {
                return (
                  <TableRow key={item.secureId} id={item.secureId} hover>
                    {columns.map((column) => {
                      const { customRender, name, sx } = column;
                      const label = item[name];
                      const labelIsValid = typeof label !== 'object';

                      return (
                        <TableCell
                          key={name + index}
                          sx={{ ...sx, fontWeight: '500' }}
                        >
                          {labelIsValid
                            ? customRender
                              ? customRender(label, data[index], index)
                              : label
                            : ''}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </Grid>
      <Grid item xs={12} flex="end">
        <TablePagination
          labelRowsPerPage="Linhas por página"
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} de ${count}`
          }
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(_, newPage) => setPage(newPage)}
          onRowsPerPageChange={({ target }) =>
            setRowsPerPage(parseInt(target.value, 10))
          }
        />
      </Grid>
    </Grid>
  );
};

export default TableComponent;
