import { call, takeEvery, all, put } from 'redux-saga/effects'

import requestSaga from './requestSaga'
import * as Url  from '../../constants/url'
import Organization from '../../types/Organization'
import { Types, Creators } from '../ducks/networkCompany'

type ResponseCompany = {
  error: boolean
  organizations: Array<Organization>
}

function* requestCompanyList() {
  yield takeEvery(Types.REQUEST_COMPANY_LIST, function* (action: any) {

    try {

      const response: ResponseCompany = 
        yield call<any>(
          requestSaga, 
          Url.NETWORK_COMPANIES_FILTER_URL, 
          'post', 
          action.filter, 
          true, 
          false
        )
        
      yield put(Creators.setCompanyList(response.organizations))

    } catch (error) {
      console.error(error)
    }
  })
}

// ToDo: Avaliar colocar essa função em um saga global para ser usado por outros módulos
function* requestCompanyCategoryListOption() {
  yield takeEvery(Types.REQUEST_COMPANY_CATEGORY_LIST_OPTION, 
    function* (action: any) {

    try {

      const { query, actionCallback } = action

      const response: ResponseCompany = 
        yield call<any>(
          requestSaga, 
          Url.CONDOMINIUM_COMPANY_CATEGORY_LIST_OPTION_URL, 
          'post', 
          query,
          true, 
          false
        )

      yield put(actionCallback(response.organizations))
    
    } catch (error) {
      console.error(error)
    }
  })
}

export default function* saga() {
  yield all([
    requestCompanyList(),
    requestCompanyCategoryListOption()
  ])
}
