import { all, call, put, takeEvery } from 'redux-saga/effects';

import toast from '../../utils/toast'
import requestSaga from './requestSaga';
import * as Url from '../../constants/url';
import { Creators, Types } from '../ducks/indication';
import ToastType from '../../constants/toastType';

type ResponseIndication = {
  error: boolean;
  message: string;
};

function* requestIndicationCompanySubmit() {
  yield takeEvery(
    Types.REQUEST_INDICATION_COMPANY_SUBMIT,
    function* (action: any) {

      try {
        const { data } = action;

        const response: ResponseIndication = yield call<any>(
          requestSaga,
          Url.INDICATIONS_COMPANY_URL,
          'post',
          data,
          true,
          false
        );

        if (response.error) {
          toast(ToastType.ERROR, response.message);
          return 
        }

        yield put(Creators.setOpenDialog(false))

        toast(ToastType.SUCCESS, response.message);

      } catch (error) {
        console.error(error)
      }
    }
  );
}

export default function* saga() {
  yield all([requestIndicationCompanySubmit()]);
}
