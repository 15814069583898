import { createActions, createReducer } from 'reduxsauce'

import CompanyInitialSettingState from '../../types/CompanyInitialSettingState'

const INITIAL_STATE: CompanyInitialSettingState = {
  isLoadingStartButton: false,
  categoryListOption: [],
  subcategoryListOption: [],
  cashbackListOption: [],
  showSuccessInitialSetting: false,
}

export const { Types, Creators } = createActions({
  resetCompanyInitialSetting: null,
  setIsLoadingStartButton: ['isLoadingStartButton'],
  setShowSuccessInitialSetting: ['showSuccess'],
  requestSettingCompanyCashbackSubmit: ['values'],
  setSettingCompanyInitialCategoryListOption: ['categoryListOption'],
  setSettingCompanyInitialSubcategoryListOption: ['subcategoryListOption'],
  setSettingCompanyInitialCashbackListOption: ['cashbackListOption']
})

const setIsLoadingStartButton = (state = INITIAL_STATE, action: any): CompanyInitialSettingState => 
  ({ ...state, isLoadingStartButton: action.isLoadingStartButton })

const setShowSuccessInitialSetting = (state = INITIAL_STATE, action: any): CompanyInitialSettingState => 
  ({ ...state, showSuccessInitialSetting: action.showSuccess })

const setSettingCompanyInitialCategoryListOption = 
  (state = INITIAL_STATE, action: any): CompanyInitialSettingState => 
    ({ ...state, categoryListOption: action.categoryListOption })

const setSettingCompanyInitialSubcategoryListOption = 
  (state = INITIAL_STATE, action: any): CompanyInitialSettingState => 
    ({ ...state, subcategoryListOption: action.subcategoryListOption })

const setSettingCompanyInitialCashbackListOption = 
  (state = INITIAL_STATE, action: any): CompanyInitialSettingState => 
    ({ ...state, cashbackListOption: action.cashbackListOption })

const resetCompanyInitialSetting = (state = INITIAL_STATE, action: any): CompanyInitialSettingState =>
  ({ ...INITIAL_STATE })

const HANDLERS = {
  [Types.SET_IS_LOADING_START_BUTTON]: setIsLoadingStartButton,
  [Types.RESET_COMPANY_INITIAL_SETTING]: resetCompanyInitialSetting,
  [Types.SET_SHOW_SUCCESS_INITIAL_SETTING]: setShowSuccessInitialSetting,
  [Types.SET_SETTING_COMPANY_INITIAL_CATEGORY_LIST_OPTION]: setSettingCompanyInitialCategoryListOption,
  [Types.SET_SETTING_COMPANY_INITIAL_SUBCATEGORY_LIST_OPTION]: setSettingCompanyInitialSubcategoryListOption,
  [Types.SET_SETTING_COMPANY_INITIAL_CASHBACK_LIST_OPTION]: setSettingCompanyInitialCashbackListOption
}

export default createReducer(INITIAL_STATE, HANDLERS)