import { call, takeEvery, all, put, select } from 'redux-saga/effects'

import requestSaga from './requestSaga'
import * as Url from '../../constants/url'
import GlobalState from '../../types/GlobalState'
import ExtractList from '../../types/ExtractList'
import { Types, Creators } from '../ducks/extract'
import * as OrganizationType from '../../constants/organizationType'

type Extract = {
  extractList: Array<unknown>
  totalBalance: number
  totalPendingBalance: number,
  hasMorePages: boolean
}

type ResponseExtractList = {
  error: boolean
  extract: Extract
}

function* requestExtractFilter() {
  yield takeEvery(Types.REQUEST_EXTRACT_FILTER, function* (action: any) {

    try {

      const { 
        listExtractOld, 
        totalBalance, 
        totalPendingBalance, 
        organizationType 
      } = yield select((state: GlobalState) => ({
        listExtractOld: state.extracts.listExtract,
        totalBalance: state.extracts.totalBalance,
        totalPendingBalance: state.extracts.totalPendingBalance,
        organizationType: state.Y2FzaGNvbg.organizationSelected?.type

      }))
      
      const response: ResponseExtractList = yield call<any>(
          requestSaga, 
          organizationType === OrganizationType.CONDOMINIUM ? Url.EXTRACTS_CONDOMINIUM_URL : Url.EXTRACTS_COMPANY_URL,
          'post',
          action.extractFilter, 
          true,
          false
        )

        

      // ToDo: refatorar para que essa propriedade textFinalValueLabel não seja passada
      const listExtract: Array<ExtractList> = response.extract.extractList.map((item: any) => ({
        textFinalValueLabel: 
          organizationType === OrganizationType.CONDOMINIUM ? 'Cashback' : 'Cashback a pagar',
        showPrices: true,
        date: item.date,
        balance: item.balance,
        data: item.monthDetails
      }))

      let extractMerged: any = action.extractFilter.page === 1 ? [] : listExtractOld

      if (action.extractFilter.page > 1) {

        listExtract.forEach((extract: ExtractList) => {
          const findExtract = extractMerged?.find((item: any) => item.date === extract.date)
          if (findExtract?.balance && extract.balance) findExtract.balance += extract.balance
          if (findExtract?.data && extract.data) findExtract.data = [...findExtract.data, ...extract.data]

          if (!findExtract) extractMerged?.push(extract)
        })
      } else {
        extractMerged = listExtract
      }

      yield put(Creators.setListExtract(extractMerged))   
      yield put(Creators.setHasMoreExtractPages(response.extract.hasMorePages))   
      yield put(Creators.setExtractTotalBalance(action.appendValue ? totalBalance + response.extract.totalBalance : response.extract.totalBalance))
      yield put(Creators.setExtractTotalPendingBalance(action.appendValue ? totalPendingBalance + response.extract.totalPendingBalance : response.extract.totalPendingBalance))

    } catch (error) {
      console.error(error)
    }
  })
}

function* requestNextExtractPage() {
  yield takeEvery(Types.REQUEST_NEXT_EXTRACT_PAGE, function* () {

    try {

      const hasMorePages: boolean = yield select((state: GlobalState): boolean => 
        state.extracts.hasMoreExtractPages)

      if (hasMorePages) yield put(Creators.setNextExtractPage())

    } catch (error) {
      console.error(error)
    }
  })
}

function* requestExtractPdf() {
  yield takeEvery(Types.REQUEST_EXTRACT_PDF, function* (action: any) {

    try {

      yield put(Creators.setExtractPdfDisabledButton(true))
      
      const token: string = yield select((state: GlobalState): string | undefined => state.Y2FzaGNvbg.user?.token)
      
      yield new Promise((resolve, reject) => {
        const xmlHttpRequest = new XMLHttpRequest();

        xmlHttpRequest.open(
          "POST",
          `${process.env.REACT_APP_API_BASE_URL}${Url.EXTRACT_PDF}`,
          true,
        );
        xmlHttpRequest.responseType = "blob";
        xmlHttpRequest.setRequestHeader("Content-Type", "application/json");
        xmlHttpRequest.setRequestHeader("Authorization", `Bearer ${token}`);

        xmlHttpRequest.onload = () => {
          if (xmlHttpRequest.status >= 200 && xmlHttpRequest.status <= 200) {
            const objectUrl = URL.createObjectURL(xmlHttpRequest.response);

            const downloadLink = document.createElement("a");
            downloadLink.style.display = "none";
            downloadLink.href = objectUrl;
            downloadLink.download = 'Extrato.pdf';

            downloadLink.dispatchEvent(new MouseEvent("click"));
            resolve(true);
          }

          reject();
        };

        xmlHttpRequest.send(JSON.stringify(action.extractFilter));
      })

      yield put(Creators.setExtractPdfDisabledButton(false));

    } catch (error) {
      console.error(error)
    }
  })
}

export default function* extractSaga() {
  yield all([
    requestExtractPdf(),
    requestExtractFilter(),
    requestNextExtractPage()
  ])
}
