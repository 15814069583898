// ToDo: Refatorar todo esse código e pensar em uma maneira melhor de fazer tratando cada caso
// ToDo: fazer uma estrutura de classe para que todos os metodos tenham acesso as variaveis
const getNumbersFromValue = (
  value: string | number = '', 
  isDecimal: boolean, 
  decimalPlaces: number
  ): string => {

    switch (typeof value) {
      case 'string':
        return value.toString().replace(/(-(?!\d))|[^0-9|-]/g, '')
      case 'number':
        return !isDecimal
          ? value.toString().replace(/(-(?!\d))|[^0-9|-]/g, '')
          : value.toFixed(decimalPlaces).replace(/(-(?!\d))|[^0-9|-]/g, '')
      default:
        return ''
    }
}

const addPaddingZero = (digits: string, paddingZero: number, isDecimal: boolean) => {
  if (!isDecimal) return digits

  const desiredLength = paddingZero
  const actualLength = digits.length

  if (actualLength >= desiredLength) return digits

  const amountToAdd = desiredLength - actualLength
  const padding = '0'.repeat(amountToAdd)
  return padding + digits
}

const removeLeadingZeros = (number: string) => number.replace(/^0+([0-9]+)/, '$1')

const addDecimalToNumber = (
  number: string, 
  separator: string, 
  decimalPlaces: number, 
  isDecimal: boolean
  ) => {
    let result: string = ''

    if (!isDecimal) {
      return number ? parseInt(number).toLocaleString('pt-br') : ''
    }

    const centsStartingPosition = number.length - decimalPlaces
    let dollars: string = removeLeadingZeros(number.substring(0, centsStartingPosition))

    if (dollars[0] === '-' && dollars[1] === '0' && dollars.length > 2) 
      dollars = dollars.replace('0', '')

    const cents: string = number.substring(centsStartingPosition)
    result = dollars + separator + cents
    return result.replace(/(?=(\d{3})+(\D))\B/g, '.')
}

export const applyMask = (
  value: string | number,
  isDecimal: boolean = false,
  separator: string = '.',
  decimalPlaces: number = 2,
  noPaddingZero: boolean = false,
  paddingZero: number = 3
  ): string => {
    const numbers: string = getNumbersFromValue(value, isDecimal, decimalPlaces)

    let numberWithPadding: string = numbers
    if (!noPaddingZero) numberWithPadding = addPaddingZero(numbers, paddingZero, isDecimal)

    return addDecimalToNumber(
      numberWithPadding,
      separator,
      decimalPlaces,
      isDecimal
    )
}


export const formatCurrencyToNumber = (value: string) => {
  return value.replaceAll('.', '').replaceAll(',', '.')
}

export const formatNumberToCnpj = (value: number | string): string => 
  String(value).replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, "$1.$2.$3/$4-$5")
