import { createActions, createReducer } from 'reduxsauce';

import SettingCondominium from '../../types/SettingCondominiumState';

const INITIAL_STATE: SettingCondominium = {
  condominium: null,
  cityListOption: [],
  openDialogDetail: false,
  condominiumRelatedList: [],
  loadingRegisterCondominium: false,
  administratorListOption: [],
};

export const { Types, Creators } = createActions({
  closeDialogDetail: null,
  resetSettingCondominium: null,
  setOpenDialogDetail: ['open'],
  setCondominium: ['condominium'],
  setCityListOption: ['cityListOption'],
  setLoadingRegisterCondominium: ['loading'],
  setCondominiumRelatedList: ['condominiumList'],
  setAdministratorListOption: ['administratorListOption'],

  // Sagas
  requestCityListOption: ['query'],
  requestRegisterCondominiumSubmit: ['data', 'actionCallback'],
  requestShowCondominium: ['secureId'],
  requestCondominiumRelatedList: null,
  requestEditCondominiumSubmit: ['data', 'actionCallback']
});

const setAdministratorListOption = (state = INITIAL_STATE, action: any): SettingCondominium =>  
  ({ ...state, administratorListOption: action.administratorListOption })

const setCityListOption = (state = INITIAL_STATE, action: any): SettingCondominium => 
  ({ ...state, cityListOption: action.cityListOption })

const setCondominiumRelatedList = (
  state = INITIAL_STATE,
  action: any
): SettingCondominium => ({
  ...state,
  condominiumRelatedList: action.condominiumList,
});

const setCondominium = (
  state = INITIAL_STATE,
  action: any
): SettingCondominium => ({
  ...state,
  condominium: action.condominium,
});

const setOpenDialogDetail = (
  state = INITIAL_STATE,
  action: any
): SettingCondominium => ({
  ...state,
  openDialogDetail: action.open,
});

const closeDialogDetail = (state = INITIAL_STATE): SettingCondominium => ({
  ...state,
  openDialogDetail: false,
  condominium: null,
});

const setLoadingRegisterCondominium = (
  state = INITIAL_STATE,
  action: any
): SettingCondominium => ({
  ...state,
  loadingRegisterCondominium: action.loading,
});

const resetSettingCondominium = (): SettingCondominium => ({
  ...INITIAL_STATE,
});

const HANDLERS = {
  [Types.SET_CONDOMINIUM]: setCondominium,
  [Types.CLOSE_DIALOG_DETAIL]: closeDialogDetail,
  [Types.SET_CITY_LIST_OPTION]: setCityListOption,
  [Types.SET_OPEN_DIALOG_DETAIL]: setOpenDialogDetail,
  [Types.RESET_SETTING_CONDOMINIUM]: resetSettingCondominium,
  [Types.SET_CONDOMINIUM_RELATED_LIST]: setCondominiumRelatedList,
  [Types.SET_LOADING_REGISTER_CONDOMINIUM]: setLoadingRegisterCondominium,
  [Types.SET_ADMINISTRATOR_LIST_OPTION]: setAdministratorListOption
};

export default createReducer(INITIAL_STATE, HANDLERS);
