import toastify from '../../utils/toast';
import { Delete, Crop } from '@mui/icons-material';
import React, { useEffect, useRef, useState } from 'react';
import {
  Controller,
  useFormContext,
  useFieldArray,
  useWatch,
  useForm,
} from 'react-hook-form';
import {
  Grid,
  Typography,
  FormHelperText,
  FormControlLabel,
  Checkbox,
} from '@mui/material';

import { Button, Dialog, Form } from '..';
import CropImage from '../CropImage';
import ToastType from '../../constants/toastType';

interface InputImageProps {
  id: string;
  name: string;
  rules?: any;
  label?: string;
  legend?: string;
  maxWidth?: number;
  maxHeight?: number;
  resizeTo: number[];
  multiple?: boolean;
  chooseMain?: boolean;
  everyShowLabel?: boolean;
  checkProportion?: boolean;
}

interface FieldValues extends HTMLImageElement {
  id: string;
  preview: {
    secureId: string;
    delete: boolean;
    url: string;
    mainImage: boolean;
  };
}

type CropImageType = {
  src: string;
  img: FieldValues;
  index: number;
  aspect: number;
  crop: any;
  imgCropped: File;
};

const cropImageDefaultValues = {
  src: '',
  img: null,
  index: -1,
  aspect: 1,
  crop: undefined,
  imgCropped: null,
};

export default function InputImage({
  id,
  name,
  rules,
  label,
  legend,
  resizeTo,
  maxWidth,
  maxHeight,
  checkProportion = true,
  multiple = false,
  chooseMain = false,
  everyShowLabel = false,
}: InputImageProps) {
  const { control, setValue, getValues } = useFormContext();
  const cropImageMethods: any = useForm({
    defaultValues: cropImageDefaultValues,
  });

  const imagesMethods: any = useFieldArray({
    name: `${name}Images`,
  });

  const images = useWatch({ name, control });

  const inputFileRef: React.RefObject<HTMLInputElement> = useRef(null);

  const [openCropModal, setOpenCropModal] = useState(false);

  const orderImagesByMain = (images: Array<FieldValues>) => {
    images.sort(
      (imgA, imgB) => (+imgB.preview.mainImage - +imgA.preview.mainImage) * 1
    );

    setValue(`${name}Images`, images);
  };

  const updateImagesArray = async (
    imagesArray: Array<any>,
    setAsMainImage = false
  ) => {
    let hasUnsupportedExtImages = false;
    let hasUnsupportedSizeImages = false;
    let hasUnsupportedProportionImages = false;

    function getSizeImage(
      file: File,
      filePreview: any
    ): Promise<{ width: number; height: number }> {
      return new Promise((resolve) => {
        const img = new Image();

        img.src = file.name ? URL.createObjectURL(file) : filePreview.url;

        img.onload = () => {
          resolve({
            width: img.naturalWidth,
            height: img.naturalHeight,
          });
        };
      });
    }

    async function resizeImage(img: any, width: number, height: number) {
      return new Promise<any>((resolve) => {
        if (img.preview) return img;

        const image = new Image();
        image.src = URL.createObjectURL(img);
        image.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d')!;

          canvas.width = width;
          canvas.height = height;
          ctx.imageSmoothingQuality = 'high';

          // ToDo: Find some way to make the background transparent
          ctx.fillStyle = 'white'
          ctx.fillRect(0, 0, 150, 150)

          ctx.drawImage(
            image,
            0,
            0,
            image.naturalWidth,
            image.naturalHeight,
            0,
            0,
            width,
            height
          );

          canvas.toBlob((blob: any) => {
            resolve(new File([blob], img.name));
          }, 'image/jpeg');
        };
      });
    }

    if (imagesArray && imagesArray.length > 0) {
      let itens = [];
      const extensions = ['jpeg', 'jpg', 'png', 'svg', 'gif'];

      for (let i = 0; i < imagesArray.length; i++) {
        const sizeImage = await getSizeImage(
          imagesArray[i],
          imagesArray[i].preview
        );
        if (
          (maxWidth && sizeImage.width > maxWidth) ||
          (maxHeight && sizeImage.height > maxHeight)
        ) {
          hasUnsupportedProportionImages = !hasUnsupportedProportionImages
            ? true
            : false;
          continue;
        }

        const proportion = Number(
          (sizeImage.width / sizeImage.height).toFixed(1)
        );

        if (
          checkProportion &&
          !imagesArray[i].preview &&
          Math.ceil(proportion) !== 1
        ) {
          openCropImageModal({
            preview: {
              secureId: '',
              delete: false,
              mainImage: setAsMainImage,
              url: URL.createObjectURL(imagesArray[i]),
            },
            file: imagesArray[i],
          });
          continue;
        }

        const image = await resizeImage(
          imagesArray[i],
          resizeTo[0],
          resizeTo[1]
        );

        if (image.size > 2000000) {
          hasUnsupportedSizeImages = !hasUnsupportedSizeImages ? true : false;
          continue;
        }

        for (let j = 0; j < extensions.length; j++) {
          if (!image.name) return;
          if (image.name.toLowerCase().endsWith(extensions[j])) {
            itens.push({
              preview: {
                secureId: '',
                delete: false,
                mainImage: setAsMainImage,
                url: URL.createObjectURL(image),
              },
              file: image,
            });
          } else {
            hasUnsupportedExtImages = !hasUnsupportedExtImages ? true : false;
          }
        }
      }

      if (hasUnsupportedSizeImages) {
        toastify(
          ToastType.ERROR,
          multiple
            ? 'O tamanho das imagens tem que ser menor que 2MB'
            : 'O tamanho da imagem tem que ser menor que 2MB'
        );
      }

      if (hasUnsupportedExtImages) {
        toastify(
          ToastType.ERROR,
          (multiple
            ? 'Algumas imagens estão em formato não suportado, Ext. válidas: '
            : 'A imagem está em formato não suportado, Etx. válidas: ') +
            extensions.join(', ')
        );
      }

      if (hasUnsupportedProportionImages) {
        toastify(ToastType.ERROR, 'Verifique as dimensões da(s) imagen(s)');
      }

      const copiedImagesFields = [...imagesMethods.fields, ...itens];
      if (multiple) {
        orderImagesByMain(copiedImagesFields);
      } else {
        setValue(`${name}Images`, copiedImagesFields);
      }

      setValue(name, null);
    }

    if (inputFileRef.current) {
      inputFileRef.current.value = '';
    }
  };

  useEffect(() => {
    if (multiple) orderImagesByMain([...imagesMethods.fields]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const files = images ? Object.values(images) : [];
    updateImagesArray(files);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [images]);

  const clearImages = () => {
    const images = imagesMethods.fields
      .filter((field: FieldValues) => field.preview.secureId)
      .map((field: FieldValues) => ({
        ...field,
        preview: {
          ...field.preview,
          delete: true,
          mainImage: false,
        },
      }));

    imagesMethods.remove();
    imagesMethods.append(images);
  };

  const findImageIndex = (itemImage: HTMLImageElement) => {
    const index = imagesMethods.fields.findIndex(
      (item: FieldValues) => item.id === itemImage.id
    );

    return index;
  };

  const removeImage = (itemImage: HTMLImageElement) => {
    const index = findImageIndex(itemImage);
    if (index === -1) return;

    if (imagesMethods.fields[index].preview.secureId !== '') {
      imagesMethods.update(index, {
        ...imagesMethods.fields[index],
        preview: {
          ...imagesMethods.fields[index].preview,
          delete: true,
        },
      });
    } else {
      imagesMethods.remove(index);
    }
  };

  const setMainImage = (itemImage: HTMLImageElement) => {
    const index = findImageIndex(itemImage);

    const allThumbnails: Array<any> = getValues(`${name}Images`);

    allThumbnails.forEach((_, i) => {
      setValue(`${name}Images.${i}.preview.mainImage`, i === index);
    });
  };

  const openCropImageModal = (itemImage: any) => {
    cropImageMethods.reset({
      ...cropImageDefaultValues,
      img: itemImage,
    });

    setOpenCropModal(true);
  };

  const cropImage = async (data: CropImageType) => {
    const { img, imgCropped } = data;

    if (imgCropped) {
      await updateImagesArray([imgCropped], img.preview.mainImage);
      removeImage(img);
    }

    setOpenCropModal(false);
  };

  const showInputImageButton = () => {
    if (multiple) return true;
    return (
      imagesMethods.fields.filter((item: FieldValues) => !item.preview.delete)
        .length === 0
    );
  };

  return (
    <>
      {showInputImageButton() && (
        <Grid item xs={12} style={{ marginBottom: 10 }}>
          <Typography variant="h6">{label}</Typography>
          <Controller
            name={name}
            control={control}
            render={({ field }) => {
              return (
                <>
                  <input
                    id={id}
                    ref={inputFileRef}
                    type="file"
                    accept="image/*"
                    multiple={multiple}
                    style={{ display: 'none' }}
                    onChange={(e) => {
                      field.onChange(e.target.files);
                    }}
                  />
                  <label htmlFor={id}>
                    <Button
                      label={`Inserir ${multiple ? 'Imagens' : 'Imagem'}`}
                      variant="contained"
                      icon="image"
                      component="span"
                    />
                  </label>
                  <Typography variant="subtitle1" gutterBottom>
                    {legend}
                    {maxWidth &&
                      maxHeight &&
                      `Dimensões máximas das imagens: ${maxWidth}x${maxHeight}`}
                  </Typography>
                  <FormHelperText
                    id={`error-${id}`}
                    style={{ color: '#f00', marginTop: 0 }}
                  ></FormHelperText>
                </>
              );
            }}
            rules={rules}
          />
        </Grid>
      )}
      <Grid container alignItems="center" spacing={2}>
        {imagesMethods.fields.map((item: FieldValues, index: number) => {
          return (
            <Grid
              key={`thumbnail-${item.id}`}
              item
              style={{ display: item.preview.delete ? 'none' : 'block' }}
            >
              {everyShowLabel && (
                <Typography variant="h6" sx={{ mb: 2 }}>
                  {label}
                </Typography>
              )}
              <div
                id={`${name}-thumbnail`}
                style={{
                  padding: 5,
                  width: '150px',
                  height: '150px',
                  display: 'flex',
                  borderRadius: '5px',
                  position: 'relative',
                  border: '1px solid rgba(0,0,0,0.32)',
                }}
              >
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center center',
                    backgroundImage: `url(${item.preview.url})`,
                  }}
                />
                <Button
                  iconButton
                  sx={{
                    position: 'absolute',
                    backgroundColor: '#ccc',
                    width: 40,
                    height: 40,
                    top: 10,
                    right: 10,
                  }}
                  onClickEvent={() => removeImage(item)}
                  icon={<Delete />}
                />
                <Button
                  iconButton
                  sx={{
                    position: 'absolute',
                    backgroundColor: '#ccc',
                    width: 40,
                    height: 40,
                    top: 55,
                    right: 10,
                  }}
                  icon={<Crop />}
                  onClickEvent={() => openCropImageModal(item)}
                />
              </div>
              {multiple && chooseMain && (
                <Controller
                  name={`${name}Images.${index}.preview.mainImage`}
                  control={control}
                  defaultValue={item.preview.mainImage}
                  render={({ field }) => {
                    return (
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={(e) => setMainImage(item)}
                            checked={field.value}
                          />
                        }
                        label="Principal"
                      />
                    );
                  }}
                />
              )}
            </Grid>
          );
        })}
        {multiple &&
          imagesMethods.fields.filter(
            (field: FieldValues) => !field.preview.delete
          ).length > 1 && (
            <Grid item xs={12}>
              <Button
                label="Remover Todas as Imagens"
                variant="contained"
                icon="delete"
                onClickEvent={clearImages}
              />
            </Grid>
          )}
      </Grid>
      <Dialog
        confirmButtonLabel="Cortar"
        openDialog={openCropModal}
        titleText="Corte de image"
        handleClose={() => setOpenCropModal(false)}
        handleConfirm={() => cropImage(cropImageMethods.getValues())}
      >
        <Form formMethods={cropImageMethods}>
          <CropImage />
        </Form>
      </Dialog>
    </>
  );
}
