import * as React from 'react'
import { AccountCircle } from '@mui/icons-material'
import { IconButton, Popover } from '@mui/material'
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state'

type ButtonDropdownProps = {
  id: string
  children: React.ReactNode
}

const ButtonDropdownComponent = (props: ButtonDropdownProps): JSX.Element => {

  const { id, children } = props

  return (
    <PopupState variant="popover" popupId={id}>
      {(popupState: any) => (
        <>
          <IconButton {...bindTrigger(popupState)}>
            <AccountCircle color='secondary' sx={{ xs: '1.6rem', md: '2.2rem' }} />
          </IconButton>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            { children }
          </Popover>
        </>
      )}
    </PopupState>
  );
}

export default ButtonDropdownComponent