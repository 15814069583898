import { call, takeEvery, all, put } from 'redux-saga/effects';

import toast from '../../utils/toast';
import requestSaga from './requestSaga';
import ToastType from '../../constants/toastType';
import Organization from '../../types/Organization';
import {
  CONDOMINIUM_RELATED_LIST_URL,
  SETTINGS_CONDOMINIUM_REGISTER_CONDOMINIUM_URL as SETTINGS_CONDOMINIUMS_REGISTER_CONDOMINIUM_URL,
  SETTINGS_CONDOMINIUM_EDIT_CONDOMINIUM_URL,
  SETTINGS_CONDOMINIUM_URL,
} from '../../constants/url';
import { Types, Creators } from '../ducks/settingCondominium';
import CondominiumDetail from '../../types/CondominiumDetail';
import { applyMask, formatNumberToCnpj } from '../../utils/numberMask';
import { formatStringToPhoneMask } from '../../utils/formatString';
import OrganizationStatusType from '../../enum/organizationStatus';

type ResponseCondominiumRelatedList = {
  error: boolean;
  condominiumList: Organization[];
};

type ResponseCondominium = {
  error: boolean;
  condominium: CondominiumDetail;
};

type ResponseCondominiumSubmit = {
  error: boolean;
  type: string;
  code: string;
};

type ResponseEditSubmit = {
  error: boolean;
  message: string;
};

function* requestCondominiumRelatedList() {
  yield takeEvery(Types.REQUEST_CONDOMINIUM_RELATED_LIST, function* () {

    try {

      const response: ResponseCondominiumRelatedList = yield call<any>(
        requestSaga,
        CONDOMINIUM_RELATED_LIST_URL,
        'get',
        {},
        true,
        false
      );

      if (response.error) {
        toast(ToastType.ERROR, 'Falha ao carregar os condomínios');
        return;
      }

      const { condominiumList } = response;

      if (condominiumList)
        yield put(Creators.setCondominiumRelatedList(condominiumList));

    } catch (error) {
      console.error(error)
    }
  });
}

function* requestShowCondominium() {
  yield takeEvery(Types.REQUEST_SHOW_CONDOMINIUM, function* (action: any) {

    try {

      const response: ResponseCondominium = yield call<any>(
        requestSaga,
        `${SETTINGS_CONDOMINIUM_URL}/${action.secureId}`,
        'get',
        {},
        true,
        false
      );

      if (response.error) {
        toast(ToastType.ERROR, 'Erro ao carregar condomínio')
        return
      }

      const { condominium } = response

      if (condominium) {
        condominium.cnpj = formatNumberToCnpj(condominium.cnpj);
        condominium.email = condominium.email || '';
        condominium.expenses = condominium.expenses ? applyMask(Number(condominium.expenses), true, ',') : ''
        condominium.status =
          condominium.status === OrganizationStatusType.ACTIVE
            ? 'Ativo'
            : OrganizationStatusType.PENDING
            ? 'Pendente'
            : 'Inativo';
        condominium.telephone = formatStringToPhoneMask(condominium.telephone)

        if (condominium.administrator?.secureId) {
          condominium.administratorSelected = { label: condominium.administrator?.name, value: condominium.administrator?.secureId }
          condominium.administratorEmail = condominium.administrator.email
          condominium.administratorTelephone = formatStringToPhoneMask(condominium.administrator.telephone)
        }

        condominium.hasInitialConfiguration = condominium.hasInitialConfiguration
          ? 'Sim'
          : 'Não';
        yield put(Creators.setCondominium(condominium));
        yield put(Creators.setOpenDialogDetail(true));
      }

    } catch (error) {
      console.error(error);
    }
    
  });
}

function* requestRegisterCondominiumSubmit() {
  yield takeEvery(
    Types.REQUEST_REGISTER_CONDOMINIUM_SUBMIT,
    function* (action: any) {

      try {

        yield put(Creators.setLoadingRegisterCondominium(true));

        const response: ResponseCondominiumSubmit = yield call<any>(
          requestSaga,
          SETTINGS_CONDOMINIUMS_REGISTER_CONDOMINIUM_URL,
          'post',
          action.data,
          true,
          false
        );

        yield put(Creators.setLoadingRegisterCondominium(false));

        if (response.error) {
          switch (response.code) {
            case '003':
              toast(ToastType.ERROR, 'Já existe um usuário com este email');
              break;
            case '004':
              toast(ToastType.ERROR, 'CNPJ já está cadastrado');
              break;
            case '005':
              toast(ToastType.ERROR, 'Falha ao cadastrar condomínio');
              break;
            case '006':
              toast(ToastType.ERROR, 'Falha ao salvar documento');
              break;
          }
          return;
        }

        toast(ToastType.SUCCESS, 'Condomínio cadastrado com sucesso');
        yield put(Creators.requestCondominiumRelatedList());

        action.actionCallback();
    
      } catch (error) {
        yield put(Creators.setLoadingRegisterCondominium(false));
        console.error(error)
      }    
    }
  );
}

function* requestEditCondominiumSubmit() {
  yield takeEvery(
    Types.REQUEST_EDIT_CONDOMINIUM_SUBMIT,
    function* (action: any) {

      try {

        yield put(Creators.setLoadingRegisterCondominium(true));

        const response: ResponseEditSubmit = yield call<any>(
          requestSaga,
          SETTINGS_CONDOMINIUM_EDIT_CONDOMINIUM_URL,
          'post',
          action.data,
          true,
          false
        );

        yield put(Creators.setLoadingRegisterCondominium(false));

        if (!response) {
          toast(ToastType.ERROR, 'Erro ao atualizar dados do condomínio');
          return;
        }

        if (response && response.error) {
          toast(ToastType.ERROR, response.message);
          return;
        }

        toast(ToastType.SUCCESS, 'Alteração feita com sucesso');
        yield put(Creators.requestCondominiumRelatedList());

        action.actionCallback();
      
      } catch (error) {
        yield put(Creators.setLoadingRegisterCondominium(false));
        console.error(error)
      }
    }
  );
}

export default function* saga() {
  yield all([
    requestShowCondominium(),
    requestEditCondominiumSubmit(),
    requestCondominiumRelatedList(),
    requestRegisterCondominiumSubmit(),
  ]);
}
