import * as React from 'react'
import { TextField, Autocomplete } from '@mui/material'
import { useFormContext, useController, RegisterOptions } from 'react-hook-form'

import errorMessage from '../../utils/errorMessage'
import SelectOption from '../../types/SelectOption'

// ToDo: options need be SelectOption object
type AutocompleteProps = {
  id: string
  label: string
  name: string
  value?: any,
  multiple?: boolean
  placeholder?: string
  rules?: RegisterOptions
  disableClearable?: boolean
  handleInputChange?: (value: string, reason: string) => void 
  handleSelectChange?: (data: any) => void 
  options: Array<SelectOption>
  disabled?: boolean
}

const AutocompleteComponent = (props: AutocompleteProps): JSX.Element => {

  const { 
    id, 
    label, 
    name, 
    value,
    multiple = false, 
    disableClearable = false,
    rules, 
    handleInputChange, 
    handleSelectChange,
    placeholder,
    options, 
    disabled = false 
  } = props

  const { control } = useFormContext()
  const { field, formState } = useController({
    name,
    control,
    rules
  })

  const requiredLabel = React.useMemo(() => {
    let requiredLabel: string = label
    if (rules?.required) {
      requiredLabel += '*'
    }
    return requiredLabel
  }, [label, rules])

  return (
    <Autocomplete
      disablePortal
      id={id}
      {...field}
      value={value || field.value || null}
      autoComplete
      disableClearable={disableClearable}
      options={options}
      multiple={multiple}
      clearText='Limpar'
      openText='Abrir'
      noOptionsText='Sem opções'
      onChange={(_, data) => {
        handleSelectChange && handleSelectChange(data);
        field.onChange(data);
      } }
      onInputChange={(_, value, reason) => {
        handleInputChange && handleInputChange(value, reason)
      }}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      renderInput={(params) => <TextField 
        {...params}
        variant='standard'
        sx={{ paddingTop: 0.7 }}
        placeholder={placeholder || 'Digite 3 letras para pesquisar'}
        label={requiredLabel} 
        error={!!formState.errors[name]}
        helperText={errorMessage(formState.errors[name], rules)}
      />}
      disabled={disabled}
    />
  )
}

export default AutocompleteComponent
