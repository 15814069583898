import { useFormContext, useController, RegisterOptions } from 'react-hook-form'
import { Box, FormGroup, FormControlLabel, FormHelperText, Checkbox } from '@mui/material'

import Text from '../Text'
import Link from '../Link'
import errorMessage from '../../utils/errorMessage'

type CheckboxProps = {
  name: string
  labelWithLink?: boolean
  label: string
  labelLink?: string
  defaultChecked?: boolean
  onLinkClickEvent?: () => void
  isAllLabelLink?: boolean
  rules?: RegisterOptions
  preventChange?: boolean
}

type LabelWithLinkProps = {
  label: string
  labelLink: string
  onLinkClickEvent?: () => void
}

const LabelWithLink = (props: LabelWithLinkProps): JSX.Element => {

  const { label, labelLink, onLinkClickEvent } = props

  return <Box
    sx={{
      display: 'flex'
    }}
  >
    <Text text={label} sx={{ mr: '5px' }}/>
    <Link 
      component='a' 
      type='body2' 
      text={labelLink}
      underline='always'
      onClickEvent={onLinkClickEvent}
    />
  </Box>
}

const CheckboxComponent = (props: CheckboxProps): JSX.Element => {

  const { 
    name,
    labelWithLink = false, 
    label, 
    labelLink = '', 
    defaultChecked = false,
    onLinkClickEvent, 
    isAllLabelLink,
    rules,
    preventChange = false
  } = props

  const { control } = useFormContext()
  const { field, formState } = useController({
    control,
    name,
    rules,
    defaultValue: defaultChecked
  })

  return (
    <FormGroup>
      <FormControlLabel 
        control={
          <Checkbox
            value={field.value}
            checked={field.value}
            onChange={(e) => !preventChange && field.onChange(e.target.checked)}
          />
        }
        sx={{ fontSize: '.8rem' }}
        onClick={isAllLabelLink ? onLinkClickEvent : undefined}
        label={labelWithLink ? 
          <LabelWithLink 
            label={label} 
            labelLink={labelLink}
            onLinkClickEvent={onLinkClickEvent}
          /> : label
        }
      />
      {
        !!formState.errors[name] && <FormHelperText error>
          {errorMessage(formState.errors[name], rules)}
        </FormHelperText>
      }
    </FormGroup>
  );
}

export default CheckboxComponent;