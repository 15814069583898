import { createActions, createReducer } from 'reduxsauce'

import SettingBankDataCondominiumState from '../../types/SettingBankDataState';

const INITIAL_STATE: SettingBankDataCondominiumState = {
  settingBankData: null,
  bankListOption: [],
  showSuccessInitialSetting: false
};

export const { Types, Creators } = createActions({
  setSettingBankDataCondominium: ['settingBankDataCondominium'],
  setSettingBankDataCondominiumBankListOption: ['bankListOption'],
  resetSettingBankDataCondominium: null,
  setShowSuccessInitialSetting: ['showSuccessInitialSetting'],

  // Sagas
  requestSettingBankDataCondominium: null,
  requestSettingBankDataCondominiumSubmit: ['settingBankDataCondominium'],
  requestSettingInitialBankDataCondominiumSubmit: ['settingBankDataCondominium']
})

const setSettingBankDataCondominium = (state = INITIAL_STATE, action: any): 
  SettingBankDataCondominiumState => 
    ({ ...state, settingBankData: action.settingBankDataCondominium })

const setSettingBankDataCondominiumBankListOption = (state = INITIAL_STATE, action: any): 
  SettingBankDataCondominiumState => 
    ({ ...state, bankListOption: action.bankListOption })
  
const setShowSuccessInitialSetting = (state = INITIAL_STATE, action: any): 
  SettingBankDataCondominiumState => 
    ({ ...state, showSuccessInitialSetting: action.showSuccessInitialSetting })

const resetSettingBankDataCondominium = (): SettingBankDataCondominiumState => 
  ({ ...INITIAL_STATE })

const HANDLERS = {
  [Types.RESET_SETTING_BANK_DATA_CONDOMINIUM]: resetSettingBankDataCondominium,
  [Types.SET_SETTING_BANK_DATA_CONDOMINIUM]: setSettingBankDataCondominium,
  [Types.SET_SETTING_BANK_DATA_CONDOMINIUM_BANK_LIST_OPTION]: 
    setSettingBankDataCondominiumBankListOption,
  [Types.SET_SHOW_SUCCESS_INITIAL_SETTING]: setShowSuccessInitialSetting
}

export default createReducer(INITIAL_STATE, HANDLERS)