import { takeEvery, all, select } from 'redux-saga/effects';

import toast from '../../../utils/toast';
import { Types } from '../../ducks/globalAction/file';
import ToastType from '../../../constants/toastType';
import GlobalState from '../../../types/GlobalState';

function* requestFile() {
  yield takeEvery(Types.REQUEST_FILE, function* (action: any) {

    try {

      const { filePath, method, useUrlApi = true, body, actionCallback } = action;

      const token: string = yield select(
        (state: GlobalState): string | undefined => state.Y2FzaGNvbg.user?.token
      );

      yield new Promise(() => {
        const xmlHttpRequest = new XMLHttpRequest();
        const path = useUrlApi
          ? `${process.env.BASE_URL_API}/${filePath}`
          : filePath;

        xmlHttpRequest.open(method, path, true);
        xmlHttpRequest.responseType = 'blob';
        xmlHttpRequest.setRequestHeader('Content-Type', 'application/json');
        xmlHttpRequest.setRequestHeader('Authorization', `Bearer ${token}`);

        xmlHttpRequest.onload = () => actionCallback(xmlHttpRequest.response);

        xmlHttpRequest.send(body ? JSON.parse(body) : undefined);
      });

    } catch (error) {
      console.error(error)
    }
  });
}

function* requestDownloadFile() {
  yield takeEvery(Types.REQUEST_DOWNLOAD_FILE, function* (action: any) {
    
    try {

      const { url, name } = action;

      yield new Promise((resolve) => {
        const xmlHttpRequest = new XMLHttpRequest();

        xmlHttpRequest.open('GET', url, true);
        xmlHttpRequest.responseType = 'blob';

        xmlHttpRequest.onload = () => {
          if (xmlHttpRequest.status >= 200 && xmlHttpRequest.status <= 200) {
            const objectUrl = URL.createObjectURL(xmlHttpRequest.response);

            const downloadLink = document.createElement('a');
            downloadLink.style.display = 'none';
            downloadLink.href = objectUrl;
            downloadLink.download = name;

            downloadLink.dispatchEvent(new MouseEvent('click'));
            resolve(true);

            return;
          }

          toast(ToastType.ERROR, 'Erro ao baixar arquivo');
        };

        xmlHttpRequest.send();
      });

    } catch (error) {
      console.error(error)
    }
  });
}

export default function* saga() {
  yield all([requestFile(), requestDownloadFile()]);
}
