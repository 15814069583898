import { useDispatch, useSelector } from 'react-redux'
import { Visibility, VisibilityOff } from '@mui/icons-material'

import Button from '../Button'
import { Creators } from '../../store/ducks/auth'
import GlobalState from '../../types/GlobalState'

const ButtonBalance = () => {
  const dispatch = useDispatch();

  const { showUserBalance } = useSelector((state: GlobalState) => ({
    showUserBalance: state.Y2FzaGNvbg.showUserBalance
  }))

  const handleBalance = () => {
    dispatch(Creators.setShowUserBalance(!showUserBalance));
  };

  return (
    <Button
      iconButton
      onClickEvent={() => handleBalance()}
      icon={showUserBalance ? <VisibilityOff /> : <Visibility />}
    />
  );
};

export default ButtonBalance;
