import {
  format,
  intervalToDuration,
  isExists,
  isFuture,
  lastDayOfMonth,
} from 'date-fns';
import removeNonAlphanumeric from './removeNonAlphanumeric';

// ToDo: find a way to format without discounting timezone with date-fns
export const convertDateWithFns = (date: Date): string =>
  format(new Date(date).setHours(24), 'dd/MM/yyyy');

export const convertDate = (date: string): string => {
  const arrayDate = date.split('/');
  return arrayDate.reverse().join('-');
};

export const getDateTimezone = (date: Date) =>
  new Date(
    Number(new Date(date)) - -new Date().getTimezoneOffset()
  ).toLocaleString('pt-BR', { dateStyle: 'short', timeStyle: 'short' });

export const removeSeconds = (date: Date) =>
  new Date(new Date(date).setSeconds(0)).toISOString();

export const getMonthName = (
  month: string | number | undefined,
  length: 'short' | 'long'
) => {
  if (!month) return '';

  const monthName = new Date(
    new Date().setMonth(Number(month) - 1)
  ).toLocaleString('pt-BR', {
    month: length,
  });

  return (
    monthName[0].toUpperCase() +
    monthName.substring(1, length === 'short' ? 3 : monthName.length)
  );
};

export const validateExpirationDate = (expirationDate: string): string | undefined => {
  if (removeNonAlphanumeric(expirationDate).length !== 6)
    return 'Insira uma data válida';

  const arrayDate = expirationDate.split('/');

  if (!isExists(parseInt(arrayDate[1]), parseInt(arrayDate[0]) - 1, 1)) {
    return 'Insira uma data válida';
  }

  const date = new Date(parseInt(arrayDate[1]), parseInt(arrayDate[0]) - 1);
  const isDateFuture = isFuture(lastDayOfMonth(date));

  if (!isDateFuture) return 'Insira uma data válida';

  const duration = intervalToDuration({
    start: new Date(),
    end: date,
  });

  if (duration.years !== undefined && duration?.years > 4) {
    return 'Data de mandato não pode ser maior que 4 anos';
  }
};
