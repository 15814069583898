import * as React from 'react'
import Link from '@mui/material/Link'
import Breadcrumbs from '@mui/material/Breadcrumbs'

type BreadcrumbProps = {
  titles: string[]
}

const Breadcrumb = (props: BreadcrumbProps): JSX.Element => {

  const { titles } = props

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    event.preventDefault();
  }

  return (
    <div role="presentation" onClick={handleClick}>
      <Breadcrumbs aria-label="breadcrumb">
        {
          titles.map((item, index) => {
            return <Link key={index} underline="none" color="inherit" sx={{cursor: 'default'}}>
              {item}
            </Link>
          })
        }
      </Breadcrumbs>
    </div>
  );
}

export default Breadcrumb
