import { createActions, createReducer } from 'reduxsauce'

import SaleState from '../../types/SaleState'

// ToDo: Remover a propriedade balance desse duck, pois o balance é uma prop global
const INITIAL_STATE: SaleState = {
  balance: 0,
  saleDisabledButton: false,
  lastSalesFilter: null,
  lastSales: null,
  saleSummary: null,
  resetSaleForm: false
}

export const { Types, Creators } = createActions({
  resetSale: null,
  setBalance: ['balance'],
  setLastSales: ['lastSales'],
  setSaleSummary: ['saleSummary'],
  setLastSalesFilter: ['lastSaleFilter'],
  setSaleDisabledButton: ['disabled'],
  resetSaleForm: ['resetSaleForm'],

  // Sagas
  requestBalance: null,
  requestLastSales: null,
  requestSaleSubmit: ['sale']
})

const resetSale = (state = INITIAL_STATE): SaleState => ({ 
  ...state,
  saleDisabledButton: false,
  lastSalesFilter: null,
  lastSales: null,
  saleSummary: null,
  resetSaleForm: false
})

const setBalance = (state = INITIAL_STATE, action: any): SaleState => 
  ({ ...state, balance: action.balance })

const setLastSales = (state = INITIAL_STATE, action: any): SaleState => 
  ({ ...state, lastSales: action.lastSales })

const setSaleSummary = (state = INITIAL_STATE, action: any): SaleState => 
  ({ ...state, saleSummary: action.saleSummary })

const setLastSalesFilter = (state = INITIAL_STATE, action: any): SaleState => 
  ({ ...state, lastSalesFilter: action.lastSaleFilter })

const setSaleDisabledButton = (state = INITIAL_STATE, action: any): SaleState => 
  ({ ...state, saleDisabledButton: action.disabled })

const resetSaleForm = (state = INITIAL_STATE, action: any): SaleState => 
  ({ ...state, resetSaleForm: action.resetSaleForm })

const HANDLERS = {
  [Types.RESET_SALE]: resetSale,
  [Types.SET_BALANCE]: setBalance,
  [Types.SET_LAST_SALES]: setLastSales,
  [Types.SET_SALE_SUMMARY]: setSaleSummary,
  [Types.SET_LAST_SALES_FILTER]: setLastSalesFilter,
  [Types.SET_SALE_DISABLED_BUTTON]: setSaleDisabledButton,
  [Types.RESET_SALE_FORM]: resetSaleForm
}

export default createReducer(INITIAL_STATE, HANDLERS)