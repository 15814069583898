import * as React from 'react'
import InputMask from 'react-input-mask'
import { SxProps, Theme } from '@mui/material/styles'
import { Add, Remove, Visibility, VisibilityOff } from '@mui/icons-material'
import { useFormContext, useController, RegisterOptions } from 'react-hook-form'
import { 
  TextField, 
  FormControl, 
  Input, 
  InputLabel, 
  InputAdornment, 
  IconButton,
  FormHelperText,
  BaseTextFieldProps
} from '@mui/material'

import { applyMask } from '../../utils/numberMask'
import errorMessage from '../../utils/errorMessage'

import './styles.css'
import { formatStringToNumber } from '../../utils/formatString'

type InputProps = {
  id: string
  name: string
  label?: string
  readOnly?: boolean
  disabled?: boolean
  type?: 'text' | 'number' | 'tel' | 'quantity' | 'password' | 'mask' | 'currency' | 'email' | 'textArea' | 'alphanumeric' | 'code-verification'
  enableNegative?: boolean
  rows?: number
  mask?: string | Array<(string | RegExp)>
  maskChar?: string | null | undefined
  rules?: RegisterOptions,
  color?: BaseTextFieldProps['color'],
  colorValue?: BaseTextFieldProps['color'],
  colorPrimaryLight?: boolean,
  focused?: boolean,
  replaceByCharacter?: string | null,
  regexReplacer?: string | null,
  onChange?: (value: string) => void | Promise<void> | null,
  sx?: SxProps<Theme>
}

const InputComponent = (props: InputProps): JSX.Element => {

  const { 
    id, 
    name, 
    label, 
    readOnly = false,
    disabled = false,
    type = 'text', 
    enableNegative = false,
    rows = 2, 
    mask = '', 
    maskChar = '_', 
    rules, 
    color = 'primary',
    colorValue,
    colorPrimaryLight = false,
    focused = false,
    replaceByCharacter = null,
    regexReplacer = null,
    onChange = null,
    sx 
  } = props
  
  let rulesInput: RegisterOptions | undefined = rules

  if (type === 'email') {
    rulesInput = {
      ...rulesInput,
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
        message: 'Formato de email inválido'
      }
    }
  }

  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const { control, setValue } = useFormContext();
  const { field, formState } = useController({
    name,
    control,
    rules: rulesInput
  });

  function quantityValueIsWrong(newValue: number): boolean {
    if (newValue < 0 && !enableNegative) return true
    if (rules && rules.max && rules.min) 
      if (newValue < rules.min || newValue > rules.max) return true
    
    return false
  }

  const handleTextChange = (e :React.ChangeEvent<HTMLInputElement>)  => {
    e.persist();

    let value = e.target.value;
    if (regexReplacer && replaceByCharacter) {
      value = value.replace(regexReplacer, replaceByCharacter);
    }

    setValue(name, value);
    if (onChange) onChange(value)
  };

  const changeQuantityHandle = (type: "-" | "+"): void => {
    const { value } = field;

    const valueFormatted = typeof value === "number" ? value : formatStringToNumber(value)
    const newValue = type === '-' ? valueFormatted - 1 : valueFormatted + 1;

    if (quantityValueIsWrong(newValue)) return
    
    setValue(name, newValue);
  }

  const handleShowPassword = (): void => {
    setShowPassword(!showPassword);
  }

  const currencyMaskHandle = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    setValue(name, applyMask(e.target.value, true, ','));
  };

  const numberMaskHandle = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();

    if (!/^\d+$/.test(e.target.value) && e.target.value !== '') return;

    setValue(name, e.target.value);
  };

  const codeHandle = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();

    const value = e.target.value
    const [idPrefix, index] = id.split('-') 
    const nextInput = document.getElementById(`${idPrefix}-${Number(index) + 1}`)

    if (/[_\W]/.test(value)) return;
    
    if (!value) {
      return
    } 
    
    if (value.length > 1) return

    if (nextInput) nextInput.focus()

    setValue(name, value.toUpperCase());
  };

  const alphaNumericHandle = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();

    if (!/^[a-zA-Z0-9]+$/.test(e.target.value) && e.target.value !== '') return;

    setValue(name, e.target.value);
  };

  const requiredLabel = React.useMemo(() => {
    let requiredLabel: string = label || ''
    if (rules?.required) {
      requiredLabel += '*'
    }
    return requiredLabel
  }, [label, rules])

  if(type === 'number') {
    return <TextField 
      id={id} 
      label={requiredLabel}
      type='text'
      {...field}
      value={field.value || ''}
      onChange={numberMaskHandle}
      variant='standard'
      error={!!formState.errors[name]}
      helperText={errorMessage(formState.errors[name], rules)}
      fullWidth
      sx={sx}
    />
  }

  if (type === 'code-verification')
    return (
      <TextField
        id={id}
        type="text"
        {...field}
        value={field.value || ''}
        autoComplete="off"
        InputProps={{
          sx: () => {
            return {
              margin: 'auto',
              pl: { xs: '1px', sm: '6px' },
              height: { xs: 50, sm: 'auto' },
              borderRadius: 4,
            };
          },
        }}
        onChange={codeHandle}
        onKeyDown={(e) => {
          if (e.key === 'Backspace') {
            if (field.value) { 
              setValue(name, '')
              return
            }
            
            const [idPrefix, index] = id.split('-') 
            const previousInput = document.getElementById(`${idPrefix}-${Number(index) - 1}`)
            previousInput?.focus()

          }
        }}
        error={!!formState.errors[name]}
        fullWidth
        sx={{ ...sx, width: { xs: 45, sm: 50 } }}
      />
    );

  if (type === 'quantity') {
    return <TextField
      id={id}
      label={requiredLabel}
      type="text"
      {...field}
      value={field.value || ''}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={() => changeQuantityHandle("+")}>
              <Add />
            </IconButton>
          </InputAdornment>
        ),
        startAdornment: (
          <InputAdornment position="start">
            <IconButton onClick={() => changeQuantityHandle("-")}>
              <Remove />
            </IconButton>
          </InputAdornment>
        )
      }}
      onChange={numberMaskHandle}
      variant="standard"
      helperText={errorMessage(formState.errors[name], rules)}
      error={!!formState.errors[name]}
      sx={sx}
    />
  }

  if(type === 'alphanumeric') {
    return <TextField 
      id={id} 
      label={requiredLabel}
      type='text'
      {...field}
      onChange={alphaNumericHandle}
      variant='standard'
      error={!!formState.errors[name]}
      helperText={errorMessage(formState.errors[name], rules)}
      fullWidth
      sx={sx}
    />
  }

  if (type === 'mask')
    return (
      <>
        <InputMask
          mask={mask}
          disabled={disabled}
          maskPlaceholder={maskChar}
          {...field}
          onChange={handleTextChange}
        >
          <FormControl fullWidth>
            <InputLabel 
              htmlFor={id} 
              error={!!formState.errors[name]}
              sx={{ left: '-15px', top: '4px' }}
            >
              {requiredLabel}
            </InputLabel>
            <Input
              id={id}
              error={!!formState.errors[name]}
              {...field}
              value={field.value || ''}
            />
            {
              !!formState.errors[name] && <FormHelperText error sx={{ marginLeft: 0 }}>
                {errorMessage(formState.errors[name], rules)}
              </FormHelperText>
            }
          </FormControl>
        </InputMask>
      </>
    );

  // ToDo: Aplicar a mascara dinamicamente usando Regex
  if (type === 'tel')
    return (
      <>
        <InputMask
          mask={
            (field.value && field.value[0] !== '(' && field.value.length === 11) ||
            (field.value?.length >= 6 && field.value?.[5] === '9')
              ? '(99) 99999-9999'
              : '(99) 9999-9999'
          }
          disabled={disabled}
          maskPlaceholder={maskChar}
          {...field}
        >
          <FormControl fullWidth>
            <InputLabel 
              htmlFor={id} 
              error={!!formState.errors[name]}
              sx={{ left: '-15px', top: '4px' }}
            >
              {requiredLabel}
            </InputLabel>
            <Input
              id={id}
              error={!!formState.errors[name]}
              {...field}
              value={field.value ?? ''}
            />
            {
              !!formState.errors[name] && <FormHelperText error sx={{ marginLeft: 0 }}>
                {errorMessage(formState.errors[name], rules)}
              </FormHelperText>
            }
          </FormControl>
        </InputMask>
      </>
    );

  if(type === 'currency')
    return <TextField 
      id={id} 
      label={requiredLabel}
      {...field}
      // ToDo: colocar applyMask dentro do value, para evitar o onchange
      onChange={currencyMaskHandle}
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'>R$</InputAdornment>
        )
      }}
      variant='standard'
      error={!!formState.errors[name]}
      helperText={errorMessage(formState.errors[name], rules)}
      fullWidth
      sx={sx}
    />

  if (type === 'email')
    return <TextField 
      id={id} 
      label={requiredLabel}
      classes={colorPrimaryLight ?  { root: 'MuiInputLight' } : {}}
      InputLabelProps={
         {classes: colorPrimaryLight ? { root: 'MuiInputLabelLight' }  : {}}
      }
      type={type}
      {...field}
      variant='standard'
      color={color}
      error={!!formState.errors[name]}
      helperText={errorMessage(formState.errors[name], rules)}
      fullWidth
      sx={{ ...sx }}
      focused={focused}
      disabled={disabled}
    />

  if (type === 'password')
    return <TextField 
      id={id} 
      label={requiredLabel}
      type={showPassword ? 'text' : 'password'}
      {...field}
      color={color}
      classes={colorPrimaryLight ? { root: 'MuiInputLight' } : {}}
      InputLabelProps={colorPrimaryLight ? { classes: { root: 'MuiInputLabelLight' } } : {}}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            <IconButton onClick={handleShowPassword}>
              {showPassword ? <VisibilityOff color={color} classes={colorPrimaryLight ? {root: 'MuiSvgIconLight'} : {}}/> : <Visibility color={color} classes={colorPrimaryLight ? {root: 'MuiSvgIconLight'} : {}} />}
            </IconButton>
          </InputAdornment>
        )
      }}
      variant='standard'
      error={!!formState.errors[name]}
      helperText={errorMessage(formState.errors[name], rules)}
      fullWidth
      sx={{ ...sx }}
      focused={focused}
    />
  
  if (type === 'textArea')
    return <TextField 
      id={id} 
      label={requiredLabel}
      {...field}
      multiline
      rows={rows}
      variant='standard'
      error={!!formState.errors[name]}
      helperText={errorMessage(formState.errors[name], rules)}
      fullWidth
      sx={sx}
    />

  return <TextField 
    id={id} 
    label={requiredLabel}
    {...field}
    value={field.value || ''}
    variant='standard'
    error={!!formState.errors[name]}
    helperText={errorMessage(formState.errors[name], rules)}
    fullWidth
    sx={sx}
    InputProps={{
      readOnly: readOnly,
      sx: { color: `${colorValue}.main` }
    }}
    disabled={disabled}
  />
};

export default InputComponent;