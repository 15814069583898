import * as React from 'react'
import { Box } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'

import Text from '../Text'
import ButtonBalance from '../ButtonBalance'
import GlobalState from '../../types/GlobalState'
import { Creators } from '../../store/ducks/sale'
import { CONDOMINIUM } from '../../constants/organizationType'
import { formatNumberToCurrency } from '../../utils/formatString'

const ValueHeader = (): JSX.Element => {

  const dispatch = useDispatch()
  const { 
    balance, 
    showUserBalance, 
    organizationSelected 
  } = useSelector((state: GlobalState) => ({
    balance: state.sales.balance,
    showUserBalance: state.Y2FzaGNvbg.showUserBalance,
    organizationSelected: state.Y2FzaGNvbg.organizationSelected
  }))

  React.useEffect(() => {
      dispatch(Creators.requestBalance())

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationSelected])

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          marginLeft: '.5rem'
        }}
      >
        {
          organizationSelected && (
            <Text
              text={organizationSelected.type === CONDOMINIUM ? 'Cashback do Mês' : 'Vendas do Mês' }
              color='primary'
              sx={{
                fontSize: '.8rem',
                fontWeight: 600
              }}
            />
          )
        }
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Text
            text={
              showUserBalance
                ? formatNumberToCurrency(balance || 0)
                : `R$ **`
            }
            sx={{
              fontSize: '1.1rem',
              fontWeight: 900,
              color: '#3a3a3a'
            }}
          />
          <ButtonBalance />
        </Box>
      </Box>
    </Box>
  )
}

export default ValueHeader
