import { all } from 'redux-saga/effects'

// ToDo: Renomear os outros sagas para listOption
import authSaga from './authSaga'
import saleSaga from './saleSaga'
import extractSaga from './extractSaga'
import bankSaga from './globalSaga/bankSaga'
import citySaga from './globalSaga/citySaga'
import fileSaga from './globalSaga/fileSaga'
import indicationSaga from './indicationSaga'
import settingCondominiumSaga from './settingCondominiumSaga'
import profileUserSaga from './profileUserSaga'
import cashbackSaga from './globalSaga/cashbackSaga'
import categorySaga from './globalSaga/categorySaga'
import networkCompanySaga from './networkCompanySaga'
import validateAccountSaga from './validateAccountSaga'
import settingCashbackSaga from './settingCashbackSaga'
import subcategorySaga from './globalSaga/subcategorySaga'
import profileOrganizationSaga from './profileOrganizationSaga'
import companyInitialSettingSaga from './companyInitialSettingSaga'
import organizationListOptionSaga from './globalSaga/organizationSaga'
import settingBankDataCondominiumSaga from './settingBankDataCondominiumSaga'

export default function* rootSaga() {
  yield all([
    authSaga(),
    saleSaga(),
    bankSaga(),
    citySaga(),
    fileSaga(),
    extractSaga(),
    cashbackSaga(),
    categorySaga(),
    indicationSaga(),
    subcategorySaga(),
    profileUserSaga(),
    networkCompanySaga(),
    validateAccountSaga(),
    settingCashbackSaga(),
    settingCondominiumSaga(),
    profileOrganizationSaga(),
    organizationListOptionSaga(),
    companyInitialSettingSaga(),
    organizationListOptionSaga(),
    settingBankDataCondominiumSaga()
  ])
}