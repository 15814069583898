import { SxProps, Theme, Box } from '@mui/material';
import { FilePresent, Close } from '@mui/icons-material';
import { useController, useFormContext, useWatch } from 'react-hook-form';

import Text from '../Text';
import Button from '../Button';
import React from 'react';

type InputFileProps = {
  id: string;
  name: string;
  label: string;
  multiple?: boolean;
  accept?: string;
  sx?: SxProps<Theme>;
};

const InputFile = (props: InputFileProps): JSX.Element => {
  const {
    id,
    name,
    label,
    multiple = false,
    accept = '.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf,.txt,.xml,.csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,.xlsx,.xls',
    sx,
  } = props;

  const { control, register, setValue } = useFormContext();
  const { formState } = useController({
    name,
    control,
  });

  const file = useWatch({
    control,
    name: `${name}`,
  });

  const removeFile = (): void => {
    setValue(`${name}`, undefined);
  };

  React.useEffect(() => {
    return () => removeFile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!file ? (
        <label htmlFor={id}>
          <input
            id={id}
            type="file"
            accept={accept}
            multiple={multiple}
            {...register(`${name}`)}
            style={{ display: 'none' }}
          />
          <Button label={label} component="span" sx={sx} />
          {!!formState.errors[name] && (
            <p style={{ color: '#d32f2f', marginTop: 3 }}>
              {formState.errors[name].message}
            </p>
          )}
        </label>
      ) : (
        <>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              ...sx,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                width: '80%',
                alignItems: 'center',
              }}
            >
              <FilePresent color="primary" fontSize="medium" />
              <Text text={`${file[0].name}`} />
            </Box>
            <Box
              sx={{
                display: 'flex',
                width: '20%',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                iconButton
                icon={<Close color="primary" />}
                onClickEvent={removeFile}
              />
            </Box>
          </Box>
          {!!formState.errors[name] && (
            <p style={{ color: '#d32f2f', marginTop: 3, fontSize: 12 }}>
              {formState.errors[name].message}
            </p>
          )}
        </>
      )}
    </>
  );
};

export default InputFile;
