import { createActions, createReducer } from 'reduxsauce'

import SettingCashbackState from '../../types/SettingCashbackState';

const INITIAL_STATE: SettingCashbackState = {
  settingCashback: null,
  categoryListOption: [],
  subcategoryListOption: [],
  cashbackListOption: [],
  isLoadingSettingCashback: false,
  settingCashbackOpenDialogTerm: false
};

export const { Types, Creators } = createActions({
  setSettingCashback: ['settingCashback'],
  resetSettingCashback: null,
  setIsLoadingSettingCashback: ['isLoadingSettingCashback'],
  setSettingCashbackOpenDialogTerm: ['settingCashbackOpenDialogTerm'],
  setSettingCashbackCategoryListOption: ['categoryListOption'],
  setSettingCashbackSubcategoryListOption: ['subcategoryListOption'],
  setSettingCashbackCashbackListOption: ['cashbackListOption'],
  
  // Sagas
  requestSettingCashback: null,
  requestSettingCashbackSubmit: ['settingCashback']
})

const setSettingCashback = (state = INITIAL_STATE, action: any): SettingCashbackState =>
  ({ ...state, settingCashback: action.settingCashback })

const resetSettingCashback = (): SettingCashbackState => ({ ...INITIAL_STATE })

const setIsLoadingSettingCashback = (state = INITIAL_STATE, action: any): SettingCashbackState =>
  ({ ...state, isLoadingSettingCashback: action.isLoadingSettingCashback })

const setSettingCashbackOpenDialogTerm = (state = INITIAL_STATE, action: any): 
  SettingCashbackState => 
    ({ ...state, settingCashbackOpenDialogTerm: action.settingCashbackOpenDialogTerm })

const setSettingCashbackCategoryListOption = (state = INITIAL_STATE, action: any): 
  SettingCashbackState => 
    ({ ...state, categoryListOption: action.categoryListOption })

const setSettingCashbackSubcategoryListOption = (state = INITIAL_STATE, action: any): 
  SettingCashbackState => 
    ({ ...state, subcategoryListOption: action.subcategoryListOption })

const setSettingCashbackCashbackListOption = (state = INITIAL_STATE, action: any): 
  SettingCashbackState => 
    ({ ...state, cashbackListOption: action.cashbackListOption })

const HANDLERS = {
  [Types.SET_SETTING_CASHBACK]: setSettingCashback,
  [Types.RESET_SETTING_CASHBACK]: resetSettingCashback,
  [Types.SET_IS_LOADING_SETTING_CASHBACK]: setIsLoadingSettingCashback,
  [Types.SET_SETTING_CASHBACK_OPEN_DIALOG_TERM]: setSettingCashbackOpenDialogTerm,
  [Types.SET_SETTING_CASHBACK_CATEGORY_LIST_OPTION]: setSettingCashbackCategoryListOption,
  [Types.SET_SETTING_CASHBACK_CASHBACK_LIST_OPTION]: setSettingCashbackCashbackListOption,
  [Types.SET_SETTING_CASHBACK_SUBCATEGORY_LIST_OPTION]: setSettingCashbackSubcategoryListOption
}

export default createReducer(INITIAL_STATE, HANDLERS)