import { createActions, createReducer } from 'reduxsauce';
import ValidateAccountState from '../../types/ValidateAccountState';

const INITIAL_STATE: ValidateAccountState = {
  userHasCode: null,
  lastCodeSentAt: '',
  loadingSubmit: false,
  loadingResendCode: false,
};

export const { Types, Creators } = createActions({
  setUserHasCode: ['hasCode'],
  setLastCodeSentAt: ['date'],
  setLoadingSubmit: ['loading'],
  setLoadingResendCode: ['loading'],

  // Sagas
  requestValidateAccountSubmit: ['code'],
  requestSendCodeSubmit: null,
  requestLastSentCode: null,
});

const setLastCodeSentAt = (
  state = INITIAL_STATE,
  action: any
): ValidateAccountState => ({
  ...state,
  lastCodeSentAt: action.date,
});

const setUserHasCode = (
  state = INITIAL_STATE,
  action: any
): ValidateAccountState => ({
  ...state,
  userHasCode: action.hasCode,
});

const setLoadingSubmit = (
  state = INITIAL_STATE,
  action: any
): ValidateAccountState => ({
  ...state,
  loadingSubmit: action.loading,
});

const setLoadingResendCode = (
  state = INITIAL_STATE,
  action: any
): ValidateAccountState => ({
  ...state,
  loadingResendCode: action.loading,
});

const HANDLERS = {
  [Types.SET_USER_HAS_CODE]: setUserHasCode,
  [Types.SET_LAST_CODE_SENT_AT]: setLastCodeSentAt,
  [Types.SET_LOADING_SUBMIT]: setLoadingSubmit,
  [Types.SET_LOADING_RESEND_CODE]: setLoadingResendCode,
};

export default createReducer(INITIAL_STATE, HANDLERS);
