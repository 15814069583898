import { call, takeEvery, all, put } from 'redux-saga/effects'

import toast from '../../utils/toast'
import requestSaga from './requestSaga'
import ToastType from '../../constants/toastType'
import SettingCashback from '../../types/SettingCashback'
import { SETTINGS_CASHBACK_COMPANY_CASHBACK_URL } from '../../constants/url'
import { Creators as CategoryCreators } from '../ducks/globalAction/category'
import { Creators as CashbackCreators } from '../ducks/globalAction/cashback'
import { Creators as SubcategoryCreators } from '../ducks/globalAction/subcategory'
import { Types, Creators as SettingCashbackCreators } from '../ducks/settingCashback'

type ResponseSettingCashback = {
  error: boolean
  settingCashback: SettingCashback
}

type ResponseSettingCashbackSubmit = {
  error: boolean
  type: string
  message: string
}

function* requestSettingCashback() {
  yield takeEvery(Types.REQUEST_SETTING_CASHBACK, function* () {

    try {

      const response: ResponseSettingCashback = 
        yield call<any>(
          requestSaga, 
          SETTINGS_CASHBACK_COMPANY_CASHBACK_URL,
          'get',
          {},
          true,
          false
        )
      
      if (response.error) {
        toast(ToastType.ERROR, 'Falha ao carregar configurações do cashback')
        return
      }

      const { settingCashback } = response

      yield put(CategoryCreators
        .requestCategoryListOption(SettingCashbackCreators.setSettingCashbackCategoryListOption)
      )
      
      if (settingCashback.categorySelected) 
        yield put(SubcategoryCreators.requestSubcategoryListOption(
          settingCashback.categorySelected?.value, 
          'checkbox', 
          SettingCashbackCreators.setSettingCashbackSubcategoryListOption
        ))

      yield put(CashbackCreators.requestCashbackListOption(
        settingCashback.categorySelected?.value,
        'options', 
        SettingCashbackCreators.setSettingCashbackCashbackListOption
      ))

      yield put(SettingCashbackCreators.setSettingCashback(settingCashback))   

    } catch (error) {
      console.error(error)
    }
  })
}

function* requestSettingCashbackSubmit() {
  yield takeEvery(Types.REQUEST_SETTING_CASHBACK_SUBMIT, function* (action: any) {

    try {

      yield put(SettingCashbackCreators.setIsLoadingSettingCashback(true))

      const response: ResponseSettingCashbackSubmit = 
        yield call<any>(
          requestSaga, 
          SETTINGS_CASHBACK_COMPANY_CASHBACK_URL,
          'post',
          action.settingCashback, 
          true,
          false
        )

      yield put(SettingCashbackCreators.setIsLoadingSettingCashback(false))
      yield put(SettingCashbackCreators.setSettingCashbackOpenDialogTerm(false))
      
      if (response.error) {
        toast(ToastType.ERROR, 'Falha ao atualizar')
        return
      }

      toast(ToastType.SUCCESS, 'Configurações atualizadas com sucesso')
      yield put(SettingCashbackCreators.requestSettingCashback())
    
    } catch (error) {
      console.error(error)
    }
  })
}

export default function* settingCashbackSaga() {
  yield all([
    requestSettingCashback(),
    requestSettingCashbackSubmit()
  ])
}
