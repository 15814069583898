import { takeEvery, call, put, all } from 'redux-saga/effects'

import toast from '../../utils/toast'
import { formatStringToPhoneMask } from '../../utils/formatString'
import requestSaga from './requestSaga'
import * as Url from '../../constants/url'
import ToastType from '../../constants/toastType'
import { Types, Creators } from '../ducks/profileUser'
import ProfileUser from '../../types/ProfileUser'

interface User extends ProfileUser { 
  cellphone: number | string
}

type ProfileUserResponse = {
  error: boolean
  message: string
  user: User
}

type ProfileUserSubmitResponse = {
  error: boolean
  type: string
  message: string
}

// ToDo: Avaliar unificar esse método com o de baixo
function* requestProfileUser() {
  yield takeEvery(Types.REQUEST_PROFILE_USER, function* () {

    try {

      yield put(Creators.setProfileUserDisabledButton(true))

      const response: ProfileUserResponse = yield call(
        requestSaga, 
        Url.PROFILE_USER_URL,
        'get', 
        {}, 
        true, 
        false
      )

      yield put(Creators.setProfileUserDisabledButton(false))
      
      if (response.error) {
        toast(ToastType.ERROR, response.message)
        return
      }

      response.user.cellphone = formatStringToPhoneMask(String(response.user.cellphone))

      toast(ToastType.SUCCESS, response.message)
      yield put(Creators.setProfileUser(response.user))

    } catch (error) {
      console.error(error)
    }
  })
}

function* requestProfileUserSubmit() {
  yield takeEvery(Types.REQUEST_PROFILE_USER_SUBMIT, function* (action: any) {

    try {

      yield put(Creators.setProfileUserDisabledButton(true))      

      const response: ProfileUserSubmitResponse = yield call(
        requestSaga, 
        Url.PROFILE_USER_URL, 
        'post', 
        action.profileUser, 
        true, 
        false
      )

      yield put(Creators.setProfileUserDisabledButton(false))
      
      if (response.error) {
        toast(ToastType.ERROR, response.message)
        return
      }
      
      toast(ToastType.SUCCESS, response.message)
      yield put(Creators.requestProfileUser())

    } catch (error) {
      console.error(error)
    }

  })
}

export default function* saga() {
  yield all([
    requestProfileUser(),
    requestProfileUserSubmit()
  ])
}