import * as React from 'react'
import { Grid, Box } from '@mui/material'
import { useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom' 
import { Home, AttachMoney, Receipt, Notifications, Business } from '@mui/icons-material'

import Text from '../Text'
import Menu from '../../types/Menu'
import menus from '../../constants/menu'
import GlobalState from '../../types/GlobalState'

// ToDo: remover esse componente
type NavigationIconProps = {
  iconKey: string
}

const NavigationIcon = (props: NavigationIconProps): JSX.Element => {

  const { iconKey } = props

  if (iconKey === 'home')
    return <Home fontSize='medium'/>

  if (iconKey === 'attach_money')
    return <AttachMoney fontSize='medium'/>
  
  if (iconKey === 'receipt')
    return <Receipt fontSize='medium'/>

  if (iconKey === 'notifications')
    return <Notifications fontSize='medium'/>

  if (iconKey === 'network_companies')
    return <Business fontSize='medium'/>
  
  return <Home />
}

const NavigationMenu = (): JSX.Element => {

  const navigate = useNavigate()
  const location = useLocation()

  const {organizationSelected, mandateExpired} = useSelector((state: GlobalState) => ({
    organizationSelected: state.Y2FzaGNvbg.organizationSelected,
    mandateExpired: state.Y2FzaGNvbg.mandateExpired
  }))


  const [menu, setMenu] = React.useState<Array<Menu>>()
  const [menuIndex, setMenuIndex] = React.useState<number>(0)

  const changeMenu = (menuIndex: number, route: string): void => {
    navigate(route)
    setMenuIndex(menuIndex)
  }

  React.useEffect(() => {
    let menuFiltered: Array<Menu> = [];
  
    menuFiltered= mandateExpired ? menus.filter(item => item.key === 'home') : organizationSelected ? menus.filter((item: Menu) => item.types.includes(organizationSelected.type)) : []
      setMenu(menuFiltered)
  
    const menuSelectedIndex = menuFiltered.findIndex(item => item.route === location.pathname)
      setMenuIndex(menuSelectedIndex >= 0 ? menuSelectedIndex : 0)
      
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mandateExpired, organizationSelected, location.pathname])

  return <Grid 
    container
    sx={{
      borderBottom: '1px solid #ccc'
    }}
  >
    {
     typeof mandateExpired === "boolean" && menu?.map((menu: Menu, index: number) => {
        return <Grid 
          key={`${menu.key}-${index}`}
          item 
          container 
          xs 
          justifyContent='center'
          sx={{
            padding: '.7rem 0',
            color: menuIndex === index ? '#03762b' : '#4e4e4e',
            borderBottom: '3px solid #fff',
            borderColor: menuIndex === index ? '#003e9e' : '#fff',
            ':hover': {
              color: '#03762b',
              backgroundColor: '#eeeeee',
              borderBottom: '3px solid #003e9e'
            }
          }}
        >
          <Box
            component='span'
            onClick={() => changeMenu(index, menu.route)}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              cursor: 'pointer'
            }}
          >
            <NavigationIcon iconKey={menu.icon} />
            <Text 
              type='body1'
              text={menu.name}
              sx={{
                display: { xs: 'none', md: 'block' },
                fontSize: '1rem',
                fontWeight: 500
              }}
            />
          </Box>
        </Grid>
      })
    }
  </Grid>
}

export default NavigationMenu